import { Injectable, OnDestroy } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  collection,
  collectionData,
  docData,
  doc,
  limit,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { firstValueFrom, of, Subscription, switchMap } from 'rxjs';

import { CloudRecordingDaily, Take } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import { CloudRecordingClassic, CloudRecordingVoice, Recording } from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';
import { ShowsService } from '../shows/shows.service';
import { Session, SessionsCollection } from '@sc/types';
import { RolesService } from '../roles/roles.service';
import { Roles } from '@sc/types';
import { setDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class RecordingsService implements OnDestroy {
  public selectedRecordings$ = new SCSubject<Array<Recording>>([]);
  public activeRecordingsTake$ = new SCSubject<number>();

  public recentOrgRecordings$ = new SCSubject<Recording[]>([]);
  public recentDashboardShowRecordings$ = new SCSubject<Recording[]>([]);

  public recentTakes$ = new SCSubject<Take[]>();
  public recentOrgTakes$ = new SCSubject<Take[]>();
  public recentShowTakes$ = new SCSubject<Take[]>();

  public recentOrgSessions$ = new SCSubject<Session[]>();
  public recentShowSessions$ = new SCSubject<Session[]>();

  recentSessions = new Set<string>();
  selectedRecordings: Array<Recording> = [];
  studioSession$ = this.sessionsService.studioSession$;
  user$ = this.userService.activeUser$;
  subs: Array<Subscription> = [];

  constructor(
    private firestore: Firestore,
    private organizationsService: OrganizationsService,
    private rolesService: RolesService,
    private sessionsService: SessionsService,
    private showsService: ShowsService,
    private userService: UserService
  ) {
    this.setupRecentTakes();
    this.setupRecentSessions();
  }

  /**
   * Returns all takes from a recording session
   *
   * @param sessionID - string
   * @returns - Observable<Take[]>
   */
  getTakesFromSession(sessionID: string, resultLimit?: number) {
    const takeRef = collection(this.firestore, 'takes') as CollectionReference<Take>;
    let q = query(takeRef, where('sessionID', '==', sessionID), orderBy('date', 'desc'));
    if (resultLimit) q = query(q, limit(resultLimit));
    return collectionData(q, { idField: 'takeID' });
  }

  getAllCloudRecordingsFromSession(sessionID: string) {
    const recordingRef = collection(
      this.firestore,
      'sessions',
      sessionID,
      SessionsCollection.CLOUD_RECORDINGS
    ) as CollectionReference<CloudRecordingClassic | CloudRecordingVoice | CloudRecordingDaily>;
    return collectionData(query(recordingRef, orderBy('timestamp', 'desc')), { idField: 'recordingID' });
  }

  /**
   * Returns all recent takes for an organization
   *
   * @param orgID - string
   * @param max - number
   * @returns - Observable<Take[]>
   */
  getRecentOrgTakes(orgID: string, max = 10) {
    const takeRef = collection(this.firestore, 'takes') as CollectionReference<Take>;
    return collectionData(query(takeRef, where('orgID', '==', orgID), orderBy('date', 'desc'), limit(max)), {
      idField: 'takeID',
    });
  }

  /**
   * Returns all recent takes for an organization
   *
   * @param orgID - string
   * @param uid - string
   * @param max - number
   * @returns - Observable<Take[]>
   */
  getRecentUserOrgTakes(orgID: string, shows: string[], max = 10) {
    const takeRef = collection(this.firestore, 'takes') as CollectionReference<Take>;
    if (shows.length > 10) shows = shows.slice(0, 10);
    return collectionData(
      query(takeRef, where('orgID', '==', orgID), where('showID', 'in', shows), orderBy('date', 'desc'), limit(max)),
      {
        idField: 'takeID',
      }
    );
  }

  /**
   * Returns all recent sessions for an organization
   *
   * @param orgID - string
   * @param max - number
   * @returns - Observable<Session[]>
   */
  getRecentOrgSessions(orgID: string, max = 10) {
    const sessionRef = collection(this.firestore, 'sessions') as CollectionReference<Session>;
    return collectionData(
      query(sessionRef, where('orgID', '==', orgID), orderBy('recordingStarted', 'desc'), limit(max)),
      {
        idField: 'sessionID',
      }
    );
  }

  /**
   * Returns all recent sessions for an organization user
   *
   * @param orgID - string
   * @param uid - string
   * @param max - number
   * @returns - Observable<Session[]>
   */
  getRecentUserOrgSessions(orgID: string, shows: string[], max = 10) {
    const sessionRef = collection(this.firestore, 'sessions') as CollectionReference<Session>;
    if (shows.length > 10) shows = shows.slice(0, 10);
    return collectionData(
      query(
        sessionRef,
        where('orgID', '==', orgID),
        where('showID', 'in', shows),
        orderBy('recordingStarted', 'desc'),
        limit(max)
      ),
      {
        idField: 'sessionID',
      }
    );
  }

  /**
   * Returns all recent takes for a show
   *
   * @param showID - string
   * @param max - number
   * @returns - Observable<Take[]>
   */
  getRecentShowTakes(showID: string, max = 10) {
    const takeRef = collection(this.firestore, 'takes') as CollectionReference<Take>;
    return collectionData(query(takeRef, where('showID', '==', showID), orderBy('date', 'desc'), limit(max)), {
      idField: 'takeID',
    });
  }

  /**
   * Returns all recent sessions for a show
   *
   * @param showID - string
   * @param max - number
   * @returns - Observable<Session[]>
   */
  getRecentShowSessions(showID: string, max = 10) {
    const sessionsCol = collection(this.firestore, 'sessions');
    return collectionData(
      query(sessionsCol, where('showID', '==', showID), orderBy('recordingStarted', 'desc'), limit(max)),
      { idField: 'sessionID' }
    );
  }

  /**
   * Removes a recording from the takes collection.
   *
   * @param recordingID - string
   * */
  async removeRecordingFromTake(recordingID: string) {
    return firstValueFrom(this.getTakeIdFromRecording(recordingID)).then(async (take) => {
      if (take[0]) {
        const takeRef = doc(this.firestore, 'takes', take[0].takeID);
        await setDoc(
          takeRef,
          {
            recordings: take[0].recordings.filter((id) => id !== recordingID),
          },
          { merge: true }
        );
      }
    });
  }

  getTakeIdFromRecording(recordingID: string) {
    const takeRef = collection(this.firestore, 'takes') as CollectionReference<Take>;
    return collectionData(query(takeRef, where('recordings', 'array-contains', recordingID), limit(1)), {
      idField: 'takeID',
    });
  }

  /**
   * Returns all recent recordings for an organization
   *
   * @param orgID - string
   * @param max - number
   * @returns - Observable<Recording[]>
   */
  // Not Used, probablly expensive, can test in the future
  // getRecentOrgRecordings(orgID: string, max = 5) {
  //   const recordingGrp = collectionGroup(this.firestore, 'recordings') as CollectionReference<Recording>;
  //   return collectionData(query(recordingGrp, where('orgID', '==', orgID), orderBy('datetime', 'desc'), limit(max)), {
  //     idField: 'recordingID',
  //   });
  // }

  /**
   * Returns all recent recordings for a show
   *
   * @param showID - string
   * @param max - number
   * @returns - Observable<Recording[]>
   */
  // Not Used, probablly expensive, can test in the future
  // getRecentShowRecordings(showID: string, max = 5) {
  //   const recordingGrp = collectionGroup(this.firestore, 'recordings') as CollectionReference<Recording>;
  //   return collectionData(query(recordingGrp, where('showID', '==', showID), orderBy('datetime', 'desc'), limit(max)), {
  //     idField: 'recordingID',
  //   });
  // }

  /**
   * Emits recent takes for org and show based on user role
   */
  async setupRecentTakes() {
    this.showsService.availableShows$
      .pipe(
        switchMap((shows) => {
          const orgID = this.organizationsService.dashboardOrgID$.value;
          const role = this.rolesService.role$.value;
          if (orgID && role >= Roles.ORG_ADMIN) return this.getRecentOrgTakes(orgID);
          else if (orgID && shows.length)
            return this.getRecentUserOrgTakes(
              orgID,
              shows.map((show) => show.showID)
            );
          else return of([]);
        })
      )
      .subscribe((take) => {
        this.recentOrgTakes$.next(take);
      });
    this.showsService.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (show?.showID && show.orgID === this.organizationsService.dashboardOrgID$.value)
            return this.getRecentShowTakes(show.showID);
          else return of([]);
        })
      )
      .subscribe((take) => {
        this.recentShowTakes$.next(take);
      });
  }

  /**
   * Emits recent sessions for org and show based on user role
   */
  setupRecentSessions() {
    this.showsService.availableShows$
      .pipe(
        switchMap((shows) => {
          const orgID = this.organizationsService.dashboardOrgID$.value;
          const role = this.rolesService.role$.value;
          if (orgID && role >= Roles.ORG_ADMIN) return this.getRecentOrgSessions(orgID);
          else if (orgID && shows.length)
            return this.getRecentUserOrgSessions(
              orgID,
              shows.map((show) => show.showID)
            );
          else return of([]);
        })
      )
      .subscribe((sessions) => {
        this.recentOrgSessions$.next(sessions);
      });
    this.showsService.dashboardShow$
      .pipe(
        switchMap((show) => {
          if (show?.showID && show.orgID === this.organizationsService.dashboardOrgID$.value)
            return this.getRecentShowSessions(show.showID);
          else return of([]);
        })
      )
      .subscribe((sessions) => {
        this.recentShowSessions$.next(sessions);
      });
  }

  getTake(id: string) {
    return docData(doc(this.firestore, 'takes', id), { idField: 'takeID' });
  }

  getAllRecordingsFromTake(sessionID: string, take: number) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    const recordingRef = collection(
      this.firestore,
      'sessions',
      sessionID,
      SessionsCollection.RECORDINGS
    ) as CollectionReference<Recording>;
    return collectionData(query(recordingRef, where('take', '==', take), orderBy('datetime', 'desc')), {
      idField: 'recordingID',
    });
  }

  addSelectedRecording(recording: Recording) {
    const recordingSelected = this.selectedRecordings.find((item) => item.recordingID === recording.recordingID);
    if (recordingSelected) return;

    this.selectedRecordings.push(recording);
    this.selectedRecordings$.next([...this.selectedRecordings]);
  }

  removeSelectedRecording(recording: Recording) {
    const recordingSelected = this.selectedRecordings.find((item) => item.recordingID === recording.recordingID);
    if (!recordingSelected) return;

    const index = this.selectedRecordings.findIndex((r) => r.recordingID === recording.recordingID);
    this.selectedRecordings.splice(index, 1);
    this.selectedRecordings$.next([...this.selectedRecordings]);
  }

  async getRecordingOffsetFromTake(recording: Recording): Promise<number> {
    const take: Take = await firstValueFrom(this.getTake(recording.takeID));
    const takeTimestampS = take.date.toMillis(); // https://firebase.google.com/docs/reference/js/firestore_.timestamp.md#timestampseconds
    const recordingTimestampS = recording.datetime.toMillis();
    const deltaTakeToRecordingTime = Math.abs(takeTimestampS - recordingTimestampS);
    let offset = 0;
    if (deltaTakeToRecordingTime > 2 * 1000) {
      offset = Math.abs((takeTimestampS - recordingTimestampS) / 1000);
    }
    return offset;
  }

  ngOnDestroy() {
    this.subs.map((sub) => sub.unsubscribe);
  }
}
