import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Firestore,
  CollectionReference,
  addDoc,
  collection,
  collectionData,
  collectionGroup,
  deleteDoc,
  doc,
  docData,
  docSnapshots,
  getDoc,
  orderBy,
  query,
  setDoc,
  where,
  arrayUnion,
  DocumentSnapshot,
  serverTimestamp,
} from '@angular/fire/firestore';
import { Observable, switchMap, firstValueFrom, of, combineLatest } from 'rxjs';
import * as dayjs from 'dayjs';
import { FeatureFlags, Roles } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { OrganizationsService } from '../organizations/organizations.service';
import { UserService } from '../user/user.service';
import { Equipment, Locations, ParticipantFsDolby, Prompt, Session, SessionDTO, SessionsCollection } from '@sc/types';
import { Recording } from '@sc/types';
import { ShowsService } from '../shows/shows.service';
import { LafayetteService } from '../lafayette/lafayette.service';
import { Router } from '@angular/router';
import { Show } from '@sc/types';
import { Organization } from '@sc/types';
import { SupportCenterService } from '../support-center/support-center.service';
import { WalletService } from '../wallet/wallet.service';
import { PilotProgram } from '@sc/types';
import { PilotProgramService } from '../pilotprogram/pilotprogram.service';
import { FeatureFlagService } from '../feature-flags/feature-flags.service';
import { ConfTypes } from '@sc/types';

@Injectable({
  providedIn: 'root',
})
export class SessionsService {
  upcomingSessions$ = new SCSubject<Session[]>();
  upcomingShowSessions$ = new SCSubject<Session[]>();
  favoriteSessions$ = new SCSubject<Session[]>();
  favoriteShowSessions$ = new SCSubject<Session[]>();
  communitySessions$ = new SCSubject<Session[]>();

  user$ = this.userService.activeUser$;
  studioSession$ = new SCSubject<Session>();
  studioSessionID$ = new SCSubject<string>();
  userRecordings$ = new SCSubject<Recording>();
  // userBackups$ = new SCSubject<CloudRecording>();
  participant$ = new SCSubject<ParticipantFsDolby>();
  participants$ = new SCSubject<ParticipantFsDolby[]>();
  participantEquipment$ = new SCSubject<Equipment>();
  participantsSidebarToggled$ = new SCSubject<boolean>();
  sessionPrompts$ = new SCSubject<Prompt[]>();

  studioShow$ = new SCSubject<Show>();
  studioShowRole$ = new SCSubject<Roles>(0);
  studioOrg$ = new SCSubject<Organization>();
  studioPilotProgram$ = new SCSubject<PilotProgram>();
  studioFeatureFlags$ = new SCSubject<FeatureFlags[]>();

  lastSession: Session;

  sessionsCol: CollectionReference = collection(this.firestore, 'sessions');

  constructor(
    private firestore: Firestore,
    private titleService: Title,
    private lafayetteService: LafayetteService,
    private userService: UserService,
    private organizationsService: OrganizationsService,
    private pilotProgramService: PilotProgramService,
    private featureFlagsService: FeatureFlagService,
    private router: Router,
    private showsService: ShowsService,
    private supportCenterService: SupportCenterService,
    private walletService: WalletService
  ) {
    this.setup();
  }

  async setup() {
    this.setupStudioSession();
    this.setupStudioShow();
    this.setupStudioShowRole();
    this.setupStudioOrg();
    this.setupStudioWallet();
    this.setupStudioPilotProgram();
    this.setupStudioFeatureFlags();
    this.setupUpcomingSessions();
    this.setupFavoriteSessions();
    this.setupParticipants();
    this.setupEquipment();
    this.setupPrompts();
    this.setupCommunitySessions();
  }

  setupEquipment() {
    this.participant$
      .pipe(
        switchMap((participant: ParticipantFsDolby) => {
          if (!participant) return of(null);
          return this.getParticipantEquipmentSnapshots(participant.uid);
        })
      )
      .subscribe((snapshot) => {
        if (!snapshot || snapshot.metadata.fromCache || snapshot.metadata.hasPendingWrites) return;
        this.participantEquipment$.next(snapshot.data() || null);
      });
  }

  /**
   * sets the observable value for Participants Sidebar Toggled
   *
   * @param toggled
   */
  setParticipantsSidebarToggled$(toggled: boolean) {
    this.participantsSidebarToggled$.next(toggled);
  }

  /**
   * Listens to the Prompts Subcolleciton for Non-resolved prompts.
   */
  setupPrompts() {
    this.studioSession$
      .pipe(
        switchMap((session: Session) => {
          if (session) return this.getAllSessionPrompts(session.sessionID);
          else return of([]);
        })
      )
      .subscribe(async (prompts: Prompt[]) => {
        const activePrompts = prompts.filter((prompt: Prompt) => !prompt.resolved);
        this.sessionPrompts$.next(activePrompts);
      });
  }

  setupParticipants(): void {
    this.studioSessionID$
      .pipe(
        switchMap((sessionID) => {
          // return this.getPariticpantInSession(userId, sessionID):
          return this.getAllParticipantsInSession(sessionID);
        })
      )
      .subscribe(async (participants: ParticipantFsDolby[]) => {
        if (!participants || !this.studioSession$.value) {
          this.participant$.next(null);
          this.participants$.next([]);
          return;
        }
        // this.participants$.next(participants);
        const currentParticipant = participants.find(
          (participant: ParticipantFsDolby) => participant.uid === this.user$.value.uid
        );
        if (currentParticipant) this.participant$.next(currentParticipant);
        else {
          const session = await this.studioSession$.nextExistingValue();
          if (session) this.addParticipant(this.user$.value.uid, session.sessionID);
        }
        this.participants$.next(participants);
      });
  }

  async addParticipant(uid: string, sessionID: string) {
    await this.studioOrg$.nextExistingValue();
    let role = this.router.url.includes('/location/backstage') ? Roles.VIEWER : Roles.GUEST;
    if (this.studioShowRole$.value) role = this.studioShowRole$.value;

    if (this.studioOrg$.value.memberIDs.includes(uid)) {
      const orgRole = await this.organizationsService.getOrgRole(this.studioOrg$.value.orgID, uid);
      if (orgRole >= Roles.ORG_ADMIN) role = orgRole;
    }

    this.setParticipantRole(uid, sessionID, role);
  }

  setParticipantRole(uid: string, sessionID: string, role: Roles) {
    return setDoc(
      doc(this.sessionsCol, `${sessionID}/${SessionsCollection.PARTICIPANTS}/${uid}`),
      { role },
      { merge: true }
    );
  }

  async setParticipantSearchInfo(uid: string, sessionID: string, info: { name?: string; email?: string }) {
    if (!info.name && !info.email) return;
    const sessionData = await firstValueFrom(this.getSessionByID(sessionID));
    if (info.name) {
      await setDoc(doc(this.sessionsCol, sessionID), { memberNames: arrayUnion(info.name) }, { merge: true });
      await this.addSearchString(sessionID, info.name);
    }
    if (info.email) {
      await this.addSearchString(sessionID, info.email);
    }

    sessionData.searchData.participantInfo[uid] = { ...info };
    await setDoc(doc(this.sessionsCol, sessionID), { searchData: sessionData.searchData }, { merge: true });

    return setDoc(
      doc(this.sessionsCol, `${sessionID}/${SessionsCollection.PARTICIPANTS}/${uid}`),
      { ...info },
      { merge: true }
    );
  }

  addSearchString(sessionID: string, value: string) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { searchStrings: arrayUnion(value) }, { merge: true });
  }

  setDescriptInstant(sessionID: string, value: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { descriptInstant: value }, { merge: true });
  }

  setupStudioOrg() {
    this.studioSession$
      .pipe(
        switchMap((session: Session) => {
          if (!session) return of(null);
          return this.organizationsService.getOrg(session.orgID);
        })
      )
      .subscribe((org) => {
        this.studioOrg$.next(org);
        this.walletService.setStudioPlan(org ? org.planID : null);
      });
  }

  setupStudioWallet() {
    this.studioOrg$
      .pipe(
        switchMap((org) => {
          if (!org) return of(null);
          return this.walletService.getWalletForOrg(org.orgID);
        })
      )
      .subscribe((wallet) => {
        this.walletService.studioWallet$.next(wallet);
      });
  }

  setupStudioPilotProgram() {
    this.studioOrg$
      .pipe(
        switchMap((org) => {
          if (!org) return of(null);
          return this.pilotProgramService.getPilotToggle(org.orgID);
        })
      )
      .subscribe((pilotProgram) => {
        if (!pilotProgram) return;
        this.studioPilotProgram$.next(pilotProgram);
      });
  }

  setupStudioFeatureFlags() {
    this.studioOrg$
      .pipe(
        switchMap((org) => {
          if (!org) return of(null);
          return this.featureFlagsService.getFeatureFlags();
        })
      )
      .subscribe((featureFlags) => {
        if (!featureFlags) return;
        this.studioFeatureFlags$.next(featureFlags);
      });
  }

  setupStudioShow() {
    this.studioSession$
      .pipe(
        switchMap((session: Session) => {
          if (!session) return of(null);
          return this.showsService.getShowByID(session.showID);
        })
      )
      .subscribe((show) => {
        if (show && !show.showImg) show.showImg = this.showsService.DEFAULT_IMG;
        this.studioShow$.next(show);
      });
  }

  setupStudioShowRole() {
    this.studioShow$.subscribe(async (show) => {
      if (!show) {
        this.studioShowRole$.next(null);
        return;
      }
      const user = await firstValueFrom(this.showsService.getShowMember(show.showID, this.user$.value.uid));
      if (!user || !this.studioSession$.value) {
        this.studioShowRole$.next(null);
        return;
      }

      this.studioShowRole$.next(user.role);

      let role = user.role;

      await this.studioOrg$.nextExistingValue();
      if (this.studioOrg$.value.orgID !== show.orgID) return;
      if (this.studioOrg$.value.memberIDs.includes(user.uid)) {
        const orgRole = await this.organizationsService.getOrgRole(this.studioOrg$.value.orgID, user.uid);
        if (orgRole >= Roles.ORG_ADMIN) role = orgRole;
      }
      this.setParticipantRole(user.uid, this.studioSession$.value.sessionID, role);
    });
  }

  setupStudioSession(): void {
    this.studioSessionID$
      .pipe(
        switchMap((sessionID: string) => {
          if (!sessionID) return of(null);
          else return this.getSessionSnapshotByID(sessionID);
        })
      )
      .subscribe((snapshot: DocumentSnapshot<Session>) => {
        if (!snapshot) {
          this.studioSession$.next(null);
          return;
        }
        if (snapshot.metadata.fromCache || snapshot.metadata.hasPendingWrites) return;
        const session = snapshot.data();
        this.studioSession$.next({ ...session, sessionID: snapshot.id });
        if (session) this.titleService.setTitle(`${session.showTitle} - ${session.sessionTitle} - SquadCast`);
      });
  }

  setStudioSession(sessionID: string) {
    if (!sessionID && this.studioSession$.value) this.lastSession = this.studioSession$.value;
    this.studioSessionID$.next(sessionID);
    if (sessionID) this.supportCenterService.supportData.sessionID = sessionID;
  }

  /**
   *  Returns all recordings for a specific user. Querys the firestore subcollection Recordings
   *
   * @param userID - String
   * @returns
   */
  getAllUserRecordings(userID?: string) {
    if (!userID) userID = this.user$.value.uid;
    const recordingGrp = collectionGroup(this.firestore, SessionsCollection.RECORDINGS);
    return collectionData<Recording>(query(recordingGrp, where('uid', '==', userID)), { idField: 'recordingID' });
  }

  /**
   * Returns all recordings in a specific session or the current user's session.
   *
   * @param sessionID - string
   * @returns Observable<Recording[]>
   */
  getAllRecordingsInSession(sessionID?: string): Observable<Recording[]> {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return collectionData<Recording>(collection(this.sessionsCol, `${sessionID}/${SessionsCollection.RECORDINGS}`), {
      idField: 'recordingID',
    });
  }

  /**
   * Returns all recordings sessions within the past couple of days
   *
   * @param days - number
   */
  getAllRecentRecordingSessions(orgID: string, days?: number) {
    if (!days) days = 5;
    const pastDate = new Date(new Date().setDate(new Date().getDate() - days));

    return collectionData<Session>(
      query(
        this.sessionsCol,
        where('orgID', '==', orgID),
        where('recordingStarted', '>=', pastDate),
        orderBy('recordingStarted', 'desc')
      ),
      { idField: 'sessionID' }
    );
  }

  /**
   * Returns all preview recordings in a specific session or the current user's session.
   *
   * @param sessionID - string
   * @returns Observable<Recording[]>
   */
  getAllPreviewRecordingsInSession(sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    const recordingRef = collection(this.sessionsCol, `${sessionID}/${SessionsCollection.RECORDINGS}`);
    return collectionData<Recording>(query(recordingRef, where('preview', '==', true)), { idField: 'recordingID' });
  }

  /**
   * Returns all recordings from a take
   *
   * @param sessionID - string
   * @param take - number
   */
  getAllRecordingsFromTake(sessionID: string, take: number) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    const recordingRef = collection(this.sessionsCol, `${sessionID}/${SessionsCollection.RECORDINGS}`);
    return collectionData<Recording>(query(recordingRef, where('take', '==', take)), { idField: 'recordingID' });
  }

  /**
   * Sets the optional Take Name property on a given recording
   *
   * @param sessionID - string
   * @param recordingID - string
   * @param takeName - string
   * @returns - Promise<void>
   */
  setRecordingsTakeName(sessionID: string, recordingID: string, takeName: string) {
    return setDoc(
      doc(this.sessionsCol, `${sessionID}/${SessionsCollection.RECORDINGS}/${recordingID}`),
      { takeName },
      { merge: true }
    );
  }

  /**
   * Returns all participants currently in a specific session or the current user's session.
   *
   * @param sessionID - string
   * @returns Observable<ParticipantFsDolby[]>
   */
  getAllParticipantsInSession(sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value?.sessionID;
    if (!sessionID) return of<ParticipantFsDolby[]>([]);
    return collectionData<ParticipantFsDolby>(
      collection(this.sessionsCol, `${sessionID}/${SessionsCollection.PARTICIPANTS}`),
      {
        idField: 'uid',
      }
    );
  }

  getUpcomingOrgSessions(orgID?: string) {
    if (!orgID) orgID = this.organizationsService.dashboardOrgID$.value;
    const today = new Date().setHours(0, 0, 0, 0);
    return collectionData<Session>(
      query(this.sessionsCol, where('orgID', '==', orgID), where('date', '>', new Date(today)), orderBy('date', 'asc')),
      {
        idField: 'sessionID',
      }
    );
  }

  getUpcomingUserSessions(userID?: string, orgID?: string) {
    if (!orgID) orgID = this.organizationsService.dashboardOrgID$.value;
    if (!userID) userID = this.user$.value.uid;
    if (!orgID || !userID) return of<Session[]>([]);
    const today = new Date().setHours(0, 0, 0, 0);
    return collectionData<Session>(
      query(
        this.sessionsCol,
        where('memberIDs', 'array-contains', userID),
        where('orgID', '==', orgID),
        where('date', '>', new Date(today)),
        orderBy('date', 'asc')
      ),
      {
        idField: 'sessionID',
      }
    );
  }

  getUpcomingShowSessions(showID: string, userID?: string) {
    const today = new Date().setHours(0, 0, 0, 0);
    let q = query(
      this.sessionsCol,
      where('showID', '==', showID),
      where('date', '>', new Date(today)),
      orderBy('date', 'asc')
    );
    if (userID) q = query(q, where('memberIDs', 'array-contains', userID));
    return collectionData<Session>(q, { idField: 'sessionID' });
  }

  getFavoriteOrgSessions(orgID?: string) {
    if (!orgID) orgID = this.organizationsService.dashboardOrgID$.value;
    return collectionData<Session>(
      query(this.sessionsCol, where('orgID', '==', orgID), where('favorite', '==', true)),
      { idField: 'sessionID' }
    );
  }

  getFavoriteUserSessions(userID?: string, orgID?: string) {
    if (!orgID) orgID = this.organizationsService.dashboardOrgID$.value;
    if (!userID) userID = this.user$.value.uid;
    if (!orgID || !userID) return of<Session[]>([]);
    return collectionData<Session>(
      query(
        this.sessionsCol,
        where('memberIDs', 'array-contains', userID),
        where('orgID', '==', orgID),
        where('favorite', '==', true)
      ),
      { idField: 'sessionID' }
    );
  }

  getFavoriteShowSessions(showID: string, userID?: string) {
    let q = query(this.sessionsCol, where('showID', '==', showID), where('favorite', '==', true));
    if (userID) q = query(q, where('memberIDs', 'array-contains', userID));
    return collectionData<Session>(q, { idField: 'sessionID' });
  }

  getCommunitySessions(userID?: string) {
    const q = query(this.sessionsCol, where('communityEvent', '==', true));
    return collectionData<Session>(q, { idField: 'sessionID' });
  }

  setFavorite(sessionID: string, favorite: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { favorite }, { merge: true });
  }

  setSessionEchoCancellatioin(sessionID: string, sessionEchoCancellation: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { sessionEchoCancellation }, { merge: true });
  }

  setSessionRecordingQuality(sessionID: string, maxQuality: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { maxQuality }, { merge: true });
  }

  setSessionConfType(sessionID: string, confType: ConfTypes) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { confType }, { merge: true });
  }

  setSessionVideoEnabled(sessionID: string, videoEnabled: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { videoEnabled }, { merge: true });
  }

  setSessionWindows4KOverride(sessionID: string, windows4KOverride: boolean) {
    return setDoc(doc(this.sessionsCol, `${sessionID}`), { windows4KOverride }, { merge: true });
  }

  /**
   *  Generates a new session document inside firestore collection and returns that document ID.
   *
   * @returns String
   */
  newSessionID() {
    const newRef = doc(this.sessionsCol);
    return newRef.id;
  }

  /**
   * Creates a new session document in the firestore collection. And generates a participants collection.
   *
   * @param session - Session
   * @param sessionID? - String
   * @returns Promise<void>
   */
  async saveSessionNew(session: SessionDTO, sessionID?: string): Promise<string> {
    if (!sessionID) sessionID = this.newSessionID();
    await this.lafayetteService.createSession(session, sessionID);
    return sessionID;
  }

  /**
   * Creates a new session document in the firestore collection. And generates a participants collection.
   *
   * @param session - Session
   * @param sessionID? - String
   * @returns Promise<void>
   */
  async updateSession(session: SessionDTO, sessionID?: string): Promise<string> {
    if (!sessionID) sessionID = this.newSessionID();
    await this.lafayetteService.updateSession(session, sessionID);
    return sessionID;
  }

  getSessionByID(sessionID: string) {
    return docData(doc<Session>(this.sessionsCol, sessionID), { idField: 'sessionID' });
  }

  getSessionSnapshotByID(sessionID: string) {
    return docSnapshots(doc<Session>(this.sessionsCol, sessionID));
  }

  deleteSession(sessionID) {
    return this.lafayetteService.deleteSession(sessionID);
  }

  /**
   * Sets the Participants Location Field in Firestore.
   * This field is used to check to see if a user is allowed in the backstage.
   *
   * @param location - 'stage' or 'backstage'
   * @param participant - ParticipantFsDolby
   * @param sessionID - string
   * @returns Promise<void>
   */
  setParticipantLocation(location: Locations, participantID: string, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    return setDoc(doc(this.sessionsCol, `${sessionID}/participants/${participantID}`), { location }, { merge: true });
  }

  /**
   * Checks the Location Field for a specific participant.
   * If the location is 'stage' then the participant is *allowed in the greenroom, else if the location is 'backstage' then the participant is allowed in the backstage.
   *
   * @param participant - ParticipantFsDolby
   * @param sessionID - string
   * @returns Promise<'stage' | 'backstage'>
   */
  async checkBackstageAccess(
    participant: ParticipantFsDolby,
    sessionID?: string,
    guestEmail?: string
  ): Promise<Locations> {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    const participantRef = doc(this.sessionsCol, `${sessionID}/participants/${participant.uid}`);
    const participantSnap = await getDoc(participantRef);
    const participantData = participantSnap.data() as ParticipantFsDolby;

    let location = Locations.NOTALLOWED;

    if (guestEmail && participantData.email === guestEmail) {
      this.setParticipantLocation(Locations.BACKSTAGE, participant.uid, sessionID);
      location = Locations.BACKSTAGE;
    } else if (participantData.location) {
      location = participantData.location;
    }
    return location;
  }

  /**
   * Creates a session prompt
   *
   * @param participant - ParticipantFsDolby
   * @param prompt - Prompt
   * @param sessionID - string
   * @returns Promise<'stage' | 'backstage'>
   */
  async createSessionPrompt(prompt: Prompt, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    const participantRef = collection(this.sessionsCol, `${sessionID}/${SessionsCollection.PROMPTS}`);
    return await addDoc(participantRef, prompt).catch((error) => {
      // https://github.com/firebase/firebase-js-sdk/issues/5549#issuecomment-1436077246
      return doc(participantRef, error.message.split('/').at(-1));
    });
  }

  /**
   * Listens to sessions prompts
   *
   * @param sessionID - string
   * @returns Observable<Prompt[]>
   */
  getAllSessionPrompts(sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    const promptRef = collection(
      this.sessionsCol,
      `${sessionID}/${SessionsCollection.PROMPTS}`
    ) as CollectionReference<Prompt>;
    return collectionData(promptRef, {
      idField: 'promptID',
    });
  }

  /**
   * Updates and Resolves any Prompt
   *
   * @param prompt - Prompt
   * @param resolution - boolean
   * @param sessionID - string
   * @returns
   */
  resolvePrompt(prompt: Prompt, resolution: boolean, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return setDoc(
      doc(this.sessionsCol, `${sessionID}/${SessionsCollection.PROMPTS}/${prompt.promptID}`),
      {
        resolved: resolution,
      },
      { merge: true }
    );
  }

  /**
   * Delete a Prompt
   *
   * @param prompt - Prompt
   * @param sessionID - string
   * @returns
   */
  deletePrompt(prompt: Prompt, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;

    return deleteDoc(doc(this.sessionsCol, `${sessionID}/${SessionsCollection.PROMPTS}/${prompt.promptID}`));
  }

  /**
   * Checks if a session exists from the URL params before navigating to the studio page.
   *
   * @param sessionID String
   * @returns Promise<boolean>
   */
  async checkSession(sessionID?: string) {
    const sessionRef = doc(this.sessionsCol, sessionID);
    const sessionSnap = await getDoc(sessionRef);
    return sessionSnap.exists();
  }

  async setupUpcomingSessions() {
    await this.user$.toPromise();
    combineLatest([this.organizationsService.orgRole$, this.organizationsService.dashboardOrgID$])
      .pipe(
        switchMap(([role]) => {
          if (role >= Roles.ORG_ADMIN) {
            return this.getUpcomingOrgSessions();
          } else {
            return this.getUpcomingUserSessions();
          }
        })
      )
      .subscribe((sessions: Session[]) => {
        this.upcomingSessions$.next(sessions);
      });
    combineLatest([this.organizationsService.orgRole$, this.showsService.dashboardShow$])
      .pipe(
        switchMap(([role, dashboardShow]) => {
          if (!dashboardShow) return of(null);
          if (role >= Roles.ORG_ADMIN) {
            return this.getUpcomingShowSessions(dashboardShow.showID);
          } else {
            return this.getUpcomingShowSessions(dashboardShow.showID, this.user$.value.uid);
          }
        })
      )
      .subscribe((sessions: Session[]) => {
        this.upcomingShowSessions$.next(sessions);
      });
  }

  async setupFavoriteSessions() {
    await this.user$.toPromise();
    combineLatest([this.organizationsService.orgRole$, this.organizationsService.dashboardOrgID$])
      .pipe(
        switchMap(([role]) => {
          if (role >= Roles.ORG_ADMIN) {
            return this.getFavoriteOrgSessions();
          } else {
            return this.getFavoriteUserSessions();
          }
        })
      )
      .subscribe((sessions: Session[]) => {
        this.favoriteSessions$.next(sessions);
      });
    combineLatest([this.organizationsService.orgRole$, this.showsService.dashboardShow$])
      .pipe(
        switchMap(([role, dashboardShow]) => {
          if (!dashboardShow) return of(null);
          if (role >= Roles.ORG_ADMIN) {
            return this.getFavoriteShowSessions(dashboardShow.showID);
          } else {
            return this.getFavoriteShowSessions(dashboardShow.showID, this.user$.value.uid);
          }
        })
      )
      .subscribe((sessions: Session[]) => {
        this.favoriteShowSessions$.next(sessions);
      });
  }

  async setupCommunitySessions() {
    await this.user$.toPromise();
    this.getCommunitySessions().subscribe((sessions: Session[]) => {
      this.communitySessions$.next(sessions);
    });
  }

  getParticipantEquipmentSnapshots(uid: string) {
    const sessionID = this.studioSession$.value.sessionID;
    return docSnapshots<Equipment>(
      doc(this.sessionsCol, sessionID, SessionsCollection.PARTICIPANTS, uid, SessionsCollection.ENV, 'equipment')
    );
  }

  getParticipantNotifications(sessionID?: string, participantID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!participantID) participantID = this.user$.value.uid;
    const nRef = collection(
      this.sessionsCol,
      sessionID,
      SessionsCollection.PARTICIPANTS,
      participantID,
      SessionsCollection.NOTIFICATIONS
    );
    return collectionData(nRef, { idField: 'id' });
  }

  setParticipantNotification(id: string, notification: any, sessionID?: string, participantID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!participantID) participantID = this.user$.value.uid;
    const docRef = doc(
      this.sessionsCol,
      sessionID,
      SessionsCollection.PARTICIPANTS,
      participantID,
      SessionsCollection.NOTIFICATIONS,
      id
    );
    return setDoc(docRef, notification);
  }

  dismissParticipantNotification(id: string, sessionID?: string, participantID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!participantID) participantID = this.user$.value.uid;
    const docRef = doc(
      this.sessionsCol,
      sessionID,
      SessionsCollection.PARTICIPANTS,
      participantID,
      SessionsCollection.NOTIFICATIONS,
      id
    );
    return setDoc(docRef, { dismissed: serverTimestamp() }, { merge: true });
  }

  async clearParticipantNotifications(sessionID: string, participantID: string) {
    const notes = await firstValueFrom(this.getParticipantNotifications(sessionID, participantID));
    notes.forEach((note) => {
      this.dismissParticipantNotification(note.id, sessionID, participantID);
    });
  }

  getSessionStats(sessionID?: string, participantID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!participantID) participantID = this.user$.value.uid;
    const statsRef = doc(this.sessionsCol, sessionID, 'participants', participantID, 'environment/connection');
    return docData(statsRef);
  }

  updateSessionStats(stats: any, sessionID?: string, participantID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (!participantID) participantID = this.user$.value.uid;

    const statsRef = doc(this.sessionsCol, sessionID, 'participants', participantID, 'environment/connection');
    return setDoc(statsRef, this.removeEmpty(stats));
  }

  // https://stackoverflow.com/a/38340730 + array handling
  removeEmpty(obj: object) {
    if (Array.isArray(obj)) return obj.map((v) => (v === Object(v) ? this.removeEmpty(v) : v));
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v])
    );
  }

  dateToString(timestamp: dayjs.ConfigType) {
    return dayjs(timestamp).format('MMM D, YYYY @ h:mm a');
  }

  secondsToString(timestampInSeconds: number) {
    const timestampInMilliseconds = timestampInSeconds * 1000;
    return dayjs(timestampInMilliseconds).format('MMM D, YYYY @ h:mm a');
  }
}
