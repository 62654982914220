import { Injectable } from '@angular/core';
import { Firestore, arrayUnion, collection, doc, docData, serverTimestamp, setDoc } from '@angular/fire/firestore';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { of, switchMap, timer } from 'rxjs';
import dayjs from 'dayjs';

import { Session } from '@sc/types';

import { Recording, RecordingDTO } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import { DolbyService } from '../dolby/dolby.service';
import { SessionsService } from '../sessions/sessions.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { StudioSidebarService } from '../../services/studio-sidebar/studio-sidebar.service';
import { RoleLimits } from '@sc/types';
import { RolesService } from '../../services/roles/roles.service';
import { FilenameService } from '../filename/filename.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { SettingsService } from '../settings/settings.service';
import { DailyService } from '../daily/daily.service';

@Injectable({
  providedIn: 'root',
})
export class RecordingService {
  studioSession$ = this.sessionsService.studioSession$;
  studioOrg$ = this.sessionsService.studioOrg$;
  recording$ = new SCSubject<boolean>();
  startRecordingCountdown$ = new SCSubject<boolean>();
  activeRecording$ = new SCSubject<Recording>();
  timer$ = new SCSubject<number>();

  startOffset = 0;
  localTimer: number;
  previewTriggered = false;

  role$ = this.rolesService.role$;
  roleLimits = RoleLimits;

  audioReady$ = new SCSubject<boolean>(false);
  videoReady$ = new SCSubject<boolean>(false);

  localRecordingID$: SCSubject<string> = new SCSubject();
  localScreenID$: SCSubject<string> = new SCSubject();
  sessionsCol = collection(this.firestore, 'sessions');
  takesCol = collection(this.firestore, 'takes');

  constructor(
    private firestore: Firestore,
    private dolbyService: DolbyService,
    private dailyService: DailyService,
    private filenameService: FilenameService,
    private organizationsService: OrganizationsService,
    private sessionsService: SessionsService,
    private toastrService: ToastrService,
    private studioSidebarService: StudioSidebarService,
    private rolesService: RolesService,
    private router: Router,
    private settingsService: SettingsService,
    private userService: UserService
  ) {
    this.setupTimer();
    this.setupActiveRecording();
    this.setupRecordingNotifications();
    this.setupFilename();
  }

  setupFilename() {
    this.recording$.subscribe((recording) => {
      if (!recording) this.filenameService.clearFileName();
      this.dailyService.recording$.next(recording);
    });
  }

  setupRecordingNotifications() {
    this.activeRecording$.subscribe((recording) => {
      if (
        recording &&
        recording.preview &&
        recording.wav &&
        !this.previewTriggered &&
        this.role$.value >= this.roleLimits.RECORDING_READ
      ) {
        setTimeout(() => {
          this.previewTriggered = true;

          const toast: ActiveToast<GeneralToastComponent> = this.toastrService.success(
            `Video/Audio previews are available in the recordings sidebar.`,
            `Quality Check`,
            {
              closeButton: true,
              tapToDismiss: false,
              toastComponent: GeneralToastComponent,
            }
          );

          toast.toastRef.componentInstance.buttons = [
            {
              label: 'Show Me!',
              handler: () => {
                this.toastrService.remove(toast.toastId);
                this.studioSidebarService.toggleSidebar('recordings', true);
              },
            },
          ];
        }, 100);
      }
    });
  }

  setupActiveRecording() {
    this.localRecordingID$.pipe(switchMap((id) => this.getRecording(id))).subscribe((recording) => {
      this.activeRecording$.next(recording);
    });
  }

  setupTimer() {
    this.studioSession$
      .pipe(
        switchMap((session) => {
          if (!session || !this.router.url.includes('studio')) {
            this.recording$.next(false);
            return of(null);
          }
          const recordingCountdown = this.settingsService.studioShowSettings$.value?.recordingCountdown;
          if (session.recording !== this.recording$.value) {
            if (recordingCountdown && session.recording === true) {
              this.startRecordingCountdown$.next(true);
              setTimeout(() => {
                this.startRecordingCountdown$.next(false);
                this.recording$.next(session.recording);
              }, recordingCountdown * 1000);
            } else {
              this.recording$.next(session.recording);
            }
          }
          if (session.recording && session.recordingStarted) {
            const startDate = dayjs(session.recordingStarted.toDate());
            this.startOffset = dayjs().diff(dayjs(startDate), 'seconds');
            if (recordingCountdown) this.startOffset = this.startOffset - recordingCountdown;
            return timer(0, 1000);
          } else return of(null);
        })
      )
      .subscribe((localTimer) => {
        if (localTimer) this.timer$.next(this.startOffset + localTimer);
        else this.timer$.next(null);
        this.localTimer = localTimer;
      });
  }

  async startRecording() {
    let session = this.studioSession$.value;
    if (session.take === undefined || session.take === null) {
      session = await this.studioSession$.nextExistingValue(
        (session: Session) => session.take !== undefined && session.take !== null
      );
    }
    if (session.recording) return;
    const takeID = this.newTake(session);
    if (this.settingsService.studioShowSettings$.value?.recordingCountdown) {
      setTimeout(() => {
        if (this.studioSession$.value?.confType === 'daily') {
          this.dailyService.startRecording(takeID);
        } else {
          this.dolbyService.startRecording();
        }
      }, this.settingsService.studioShowSettings$.value?.recordingCountdown * 1000);
    } else {
      if (this.studioSession$.value?.confType === 'daily') {
        this.dailyService.startRecording(takeID);
      } else {
        this.dolbyService.startRecording();
      }
    }
    const sessionRef = doc(this.sessionsCol, session.sessionID);
    if (!this.studioOrg$.value.hasRecorded) {
      this.organizationsService.setOrgHasRecorded(this.studioOrg$.value.orgID);
    }
    return setDoc(
      sessionRef,
      {
        recording: true,
        recordingStarted: serverTimestamp(),
        take: session.take + 1,
        takeID,
      },
      { merge: true }
    );
  }

  newTake(session: Session) {
    const takeRef = doc(this.takesCol);
    setDoc(takeRef, {
      sessionID: session.sessionID,
      showID: session.showID,
      orgID: session.orgID,
      takeNumber: session.take + 1,
      date: serverTimestamp(),
    });
    return takeRef.id;
  }

  stopRecording(sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    if (this.studioSession$.value?.confType === 'daily') {
      this.dailyService.stopRecording();
    } else {
      this.dolbyService.stopRecording();
    }
    return setDoc(doc(this.sessionsCol, sessionID), { recording: false }, { merge: true });
  }

  getRecording(recordingID: string, sessionID?: string) {
    if (!sessionID)
      sessionID = this.studioSession$.value?.sessionID
        ? this.studioSession$.value?.sessionID
        : this.sessionsService.lastSession.sessionID;
    const recordingRef = doc(this.sessionsCol, sessionID, 'recordings', recordingID);
    return docData(recordingRef, { idField: 'recordingID' });
  }

  setRecording(recordingID: string, recording: RecordingDTO) {
    const session = this.studioSession$.value;
    this.addRecordingToTake(session.takeID, recordingID);
    recording.orgID = session.orgID;
    recording.sessionID = session.sessionID;
    recording.take = session.take;
    recording.takeID = session.takeID;
    recording.participantID = this.userService.activeUser$.value.uid;
    recording.showID = session.showID;
    const recordingRef = doc(this.sessionsCol, this.studioSession$.value.sessionID, 'recordings', recordingID);
    return setDoc(recordingRef, recording, { merge: true });
  }

  updateRecording(recordingID: string, recording: RecordingDTO) {
    const sessionID = this.studioSession$.value?.sessionID || this.sessionsService.lastSession.sessionID;
    const recordingRef = doc(this.sessionsCol, sessionID, 'recordings', recordingID);
    return setDoc(recordingRef, recording, { merge: true });
  }

  getTake(takeID: string) {
    return docData(doc(this.takesCol, takeID), { idField: 'takeID' });
  }

  addRecordingToTake(takeID: string, recordingID: string) {
    return setDoc(doc(this.takesCol, takeID), { recordings: arrayUnion(recordingID) }, { merge: true });
  }

  setTakeName(takeID: string, name: string) {
    return setDoc(doc(this.takesCol, takeID), { takeName: name }, { merge: true });
  }

  resetLocalRecordingID() {
    const sessionID = this.studioSession$.value.sessionID;
    const recordingRef = doc(collection(this.sessionsCol, sessionID, 'recordings'));
    this.localRecordingID$.next(recordingRef.id);
    this.previewTriggered = false;
  }
}
