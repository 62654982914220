import * as Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';
import { APP_VERSION } from '../../../environments/app.version';

let env = 'dev';
if (environment.production) env = 'production';
if (environment.beta) env = 'beta';
if (environment.production && window.location.hostname !== 'app.squadcast.fm') env = 'staging';

// const transformer = (payload) => {
//   if (env === 'dev') console.log('RollbarPayload', payload);
// };

const checkIgnore: Rollbar.Configuration['checkIgnore'] = (isUncaught, args, payload) => {
  // Sample firebase permission errors at 10%
  if (args[0].toString().includes('Missing or insufficient permissions.') && Math.random() > 0.1) return true;
  return false;
};
const rollbarConfig: Rollbar.Configuration = {
  itemsPerMinute: 60,
  maxItems: 200,
  accessToken: environment.rollbar.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: env,
  verbose: !environment.production,
  // transmit: environment.production || environment.staging || environment.beta, // Can change to true to send local dev to rollbar
  transmit: true,
  // transform: transformer,
  checkIgnore,
  ignoredMessages: ['currentRoundTripTime', 'WebSocketError: 1006'],
  payload: {
    client: {
      javascript: {
        source_map_enabled: environment.production || environment.staging || environment.beta,
        code_version: APP_VERSION.commitSha,
        guess_uncaught_frames: true,
      },
    },
    server: {
      root: 'webpack:///./apps/epsilon/src/',
      branch: APP_VERSION.branch,
    },
  },
};

if (env !== 'production') console.log('Rollbar Config: ', rollbarConfig);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    if (err.zoneAwareStack) {
      err.stack = err.zoneAwareStack;
    }
    this.rollbar.error(err.originalError || err);
  }
}

export const rollbarFactory = () => {
  return new Rollbar(rollbarConfig);
};
