import { Inject, Injectable } from '@angular/core';
import { Storage, StorageReference, UploadTask, ref, uploadBytesResumable } from '@angular/fire/storage';
import { Firestore, doc, collection, serverTimestamp } from '@angular/fire/firestore';
import { BehaviorSubject, combineLatest, firstValueFrom, skipWhile, Subscription } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import * as dayjs from 'dayjs';

import { UserService } from '../user/user.service';
import { FilenameService } from '../filename/filename.service';
import { SessionsService } from '../sessions/sessions.service';
import { RecordingService } from '../recording/recording.service';
import { DolbyService } from '../dolby/dolby.service';
import { IdTokenService } from '../id-token/id-token.service';
import { FarnsworthService } from '../farnsworth/farnsworth.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { Locations, Plan } from '@sc/types';
import { UserModel } from '@sc/types';
import { WalletService } from '../wallet/wallet.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { RollbarService } from '../rollbar/rollbar.service';
import Rollbar from 'rollbar';
import { StickAroundToastComponent } from '../../toasts/stick-around-toast/stick-around-toast.component';
import { StatsType } from '@sc/types';
import { EquipmentService } from '../equipment/equipment.service';
import { StatsService } from '../stats/stats.service';
import { DailyService } from '../daily/daily.service';

@Injectable({
  providedIn: 'root',
})
export class ScreenRecorderService {
  user: UserModel;
  session$ = this.sessionsService.studioSession$;
  plan$ = this.walletService.studioPlan$;
  dolbyConversation$ = this.dolbyService.dolbyConversation$;
  dailyCall$ = this.dailyService.dailyCall$;
  recorder: MediaRecorder;
  screen: MediaStream;
  TIMESLICE = 4 * 1000;
  filename: string;
  recordingID: string;
  recording = false;
  chunkCounter = 0;
  plan: Plan;
  participantID: string;
  recordingInfo = {};
  recordingUpdate$ = new BehaviorSubject(null);

  localRecordingSub: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private dolbyService: DolbyService,
    private dailyService: DailyService,
    private equipmentService: EquipmentService,
    private filenameService: FilenameService,
    private firestore: Firestore,
    private idTokenService: IdTokenService,
    private farnsworthService: FarnsworthService,
    private recordingService: RecordingService,
    private sessionsService: SessionsService,
    private statsService: StatsService,
    private storage: Storage,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.setupUser();
    if (this.dolbyConversation$.value) {
      this.setupRecordingStartDolby();
      this.setupScreenDolby();
    }
    if (this.dailyCall$.value) {
      this.setupRecordingStartDaily();
      this.setupScreenDaily();
    }
    this.setupFilename();
    this.storage.maxUploadRetryTime = 60 * 1000;
  }

  setupRecordingStartDolby() {
    combineLatest([this.recordingService.recording$, this.dolbyConversation$, this.dolbyService.location$]).subscribe(
      ([recording, convo, location]) => {
        this.recording = recording;
        if (!convo?.participants?.size) {
          this.stop();
          return;
        }
        const participantIsOnStage =
          location !== Locations.BACKSTAGE &&
          !!Array.from(convo?.participants?.values()).find((p) => p.type === 'user');
        if (convo && recording && participantIsOnStage) this.start();
        else this.stop();
      }
    );
  }

  setupRecordingStartDaily() {
    // no Backstage in Daily calls
    combineLatest([this.recordingService.recording$, this.dailyCall$]).subscribe(([recording, call]) => {
      this.recording = recording;
      if (call && recording) this.start();
      else this.stop();
    });
  }

  async start() {
    if (
      this.recorder?.state === 'recording' ||
      !this.plan$.value.videoRecording ||
      !this.session$.value.videoEnabled ||
      !this.screen ||
      this.user.uid !== this.participantID
    ) {
      this.recordingID = null;
      return;
    }

    await this.setupRefs();
    this.setupRecorder();

    if (this.recordingInfo[this.recordingID].stopped) return;
    this.recorder.start(this.TIMESLICE);
  }

  stop() {
    if (this.recordingInfo[this.recordingID]) this.recordingInfo[this.recordingID].stopped = true;
    if (!this.recorder || this.recorder.state === 'inactive' || this.user.uid !== this.participantID) {
      return;
    }
    this.recorder.stop();
  }

  async setupUser() {
    this.userService.activeUser$.subscribe((user) => {
      this.user = user;
    });
  }

  setupRecorder() {
    this.chunkCounter = 0;
    const recordingID = this.recordingID;
    this.recordingInfo[recordingID] = {
      sessionID: this.session$.value.sessionID,
      showID: this.session$.value.showID,
      filename: this.filename,
      take: this.session$.value.take,
      queue: new Map(),
      stats: { screen: { ...this.statsService.defaultRecordingStats } },
      lastBytesTransferred: 0,
    };
    this.statsService.updateRecordingStats(
      recordingID,
      StatsType.SCREEN,
      this.recordingInfo[recordingID].stats.screen,
      this.recordingInfo[recordingID].sessionID
    );
    this.recordingUpdate$.next(recordingID);

    this.recorder = this.equipmentService.getVideoRecorder(this.screen);

    this.recordingInfo[this.recordingID].stats.screen.trackSettings = {
      video: this.recorder.stream.getVideoTracks()[0].getSettings(),
    };

    this.recorder.ondataavailable = this.handleRecording.bind(this);
    this.recorder.onstop = this.watchProcessing.bind(this);
    this.recorder.onerror = this.handleError.bind(this);
  }

  async setupRefs() {
    const newDocRef = doc(collection(this.firestore, 'sessions'));
    this.recordingID = newDocRef.id;
    this.recordingService.localScreenID$.next(this.recordingID);
    this.filename = await this.filenameService.fileName$.nextExistingValue();
    // add screen to file name
    if (!this.filename.includes('-screen')) {
      const nameArr = this.filename.split('_');
      nameArr[0] = nameArr[0] + '-screen';
      this.filename = nameArr.join('_');
    }

    this.filename = `${this.filename.split('_')[0]}_${this.filename.split('_')[1]}_${dayjs().format(
      'MM-DD-YYYY_HHmmss'
    )}`;

    this.recordingService.setRecording(this.recordingID, {
      screenRecording: true,
      hadVideoPlanAtRecording: true,
      fileName: this.filename,
      datetime: serverTimestamp(),
    });
  }

  setupFilename() {
    this.filenameService.fileName$.subscribe((filename) => {
      if (filename) this.filename = filename;
    });
  }

  setupScreenDolby() {
    this.dolbyService.screenshare$.subscribe((screenParticipant) => {
      if (screenParticipant) {
        this.screen = screenParticipant.activeStream;
        this.participantID = screenParticipant.info.externalId;
        if (this.recording) this.start();
      } else {
        if (this.recording) this.stop();
        this.screen = null;
        this.participantID = null;
      }
    });
  }

  setupScreenDaily() {
    this.dailyService.screenshare$.subscribe((screenParticipant) => {
      if (screenParticipant) {
        this.screen = screenParticipant.activeStream;
        this.participantID = screenParticipant.userData.scUID;
        if (this.recording) this.start();
      } else {
        if (this.recording) this.stop();
        this.screen = null;
        this.participantID = null;
      }
    });
  }

  async readyToUpload(recordingID: string, num: number) {
    await firstValueFrom(
      this.recordingUpdate$.pipe(
        skipWhile(() => {
          return this.recordingInfo[recordingID].queue.has(num - 1);
        })
      )
    );
    return true;
  }

  async handleRecording(recording: { data: Blob }, retryChunk = 0) {
    if (typeof recording.data === 'undefined' || recording.data.size === 0) {
      return;
    }
    if (!retryChunk) this.chunkCounter = this.chunkCounter + 1;
    const chunkNum = retryChunk || this.chunkCounter;
    const recordingID = this.recordingID;
    const recRef = this.recordingInfo[this.recordingID];
    const filename = recRef.filename;
    const blob = recording.data;

    if (!retryChunk) {
      this.statsService.statsNewChunk(recRef, recordingID, StatsType.SCREEN, chunkNum, blob);
      await this.readyToUpload(recordingID, chunkNum);
    }

    const chunkRef = ref(this.storage, `${recRef.showID}/${recRef.sessionID}/${filename}`);
    let fileRef: StorageReference;
    let chunkUpload: UploadTask;
    const t0 = performance.now();

    if (this.recorder.mimeType.includes('webm')) {
      fileRef = ref(chunkRef, `${chunkNum}.webm`);
      chunkUpload = uploadBytesResumable(fileRef, blob, { contentType: 'video/webm' });
    } else {
      fileRef = ref(chunkRef, `${chunkNum}.mp4`);
      chunkUpload = uploadBytesResumable(fileRef, blob, { contentType: 'video/mp4' });
    }

    if (!retryChunk) this.statsService.statsStartUpload(recRef, StatsType.SCREEN, chunkNum, blob);

    chunkUpload.on(
      'state_changed',
      (snapshot) => {
        this.statsService.statsChunkProgress(
          recRef,
          recordingID,
          StatsType.SCREEN,
          chunkNum,
          snapshot,
          this.recordingUpdate$
        );
      },
      (error: Error) => {
        this.statsService.statsChunkError(recRef, recordingID, StatsType.SCREEN, chunkNum, this.recordingUpdate$);
        this.rollbar.warn('Screen Chunk Upload Error', { error, recordingInfo: this.recordingInfo });
        this.analyticsService.track('errored uploading screen', {
          showID: recRef.showID,
          sessionID: recRef.sessionID,
          castMemberID: this.user.uid,
          recordingID,
          screenRecording: true,
          fileName: filename,
          error: error.message,
        });

        this.toastrService.warning(
          'There is a problem uploading the screenshare files.  We will continue to retry the upload.  Please double check your network connection.',
          'Screenshare Upload Stalled',
          {
            closeButton: true,
            tapToDismiss: false,
            timeOut: 20 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );

        this.handleRecording({ data: blob }, chunkNum);
      },
      () => {
        this.statsService.statsChunkComplete(
          recRef,
          recordingID,
          StatsType.SCREEN,
          chunkNum,
          t0,
          this.recordingUpdate$
        );
      }
    );
  }

  watchProcessing() {
    const info = this.recordingInfo[this.recordingID];
    info.awaitingProcessing = true;

    if (info.queue.size) {
      const targetID = this.recordingID;
      const watchSubscription = this.recordingUpdate$.subscribe((recordingID) => {
        if (recordingID === targetID) {
          if (info.queue.size === 0) {
            this.processFile(recordingID);
            if (info.stickAroundToast) info.stickAroundToast.toastRef.close();
            watchSubscription.unsubscribe();
          }
        }
      });
    } else this.processFile();
  }

  showStickAround(screenID) {
    this.recordingInfo[screenID].stickAroundToast = this.toastrService.error(
      `This tab must remain open for your recorded files to be uploaded.`,
      `Do not close this browser tab!`,
      {
        progressBar: false,
        closeButton: false,
        tapToDismiss: false,
        disableTimeOut: true,
        toastComponent: StickAroundToastComponent,
        payload: { screenID },
      }
    ) as ActiveToast<StickAroundToastComponent>;
  }

  async processFile(recordingID = this.recordingID) {
    this.recordingInfo[recordingID].awaitingProcessing = false;
    try {
      setTimeout(async () => {
        const idToken = await this.idTokenService.getFreshIdToken();
        await firstValueFrom(
          this.farnsworthService.processRecording(
            {
              showID: this.recordingInfo[recordingID].showID,
              sessionID: this.recordingInfo[recordingID].sessionID,
              castMemberID: this.user.uid,
              recordingID,
              fileName: this.filename,
              videoOnly: true,
            },
            idToken
          )
        );

        if (this.recordingService.localScreenID$.value === recordingID) this.recordingService.localScreenID$.next(null);

        this.toastrService.success(this.filename, `Successfully rendered Screen Recording`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        });

        this.analyticsService.track('processed recording', {
          showID: this.recordingInfo[recordingID].showID,
          sessionID: this.recordingInfo[recordingID].sessionID,
          castMemberID: this.user.uid,
          recordingID,
          fileName: this.filename,
          screenRecording: true,
          success: true,
        });
      }, 4000);
    } catch (error) {
      this.rollbar.error('Screen recorder error: ', error, this.recordingInfo);
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.warning(
        `Cloud Recordings are always available as a backup`,
        `Failed to render Screen Recording`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      toast.toastRef.componentInstance.learnMoreTopic = 'cloudRecordings';
      this.analyticsService.track('processed recording', {
        showID: this.recordingInfo[recordingID].showID,
        sessionID: this.recordingInfo[recordingID].sessionID,
        castMemberID: this.user.uid,
        recordingID,
        fileName: this.filename,
        screenRecording: true,
        success: false,
      });
    }
  }

  handleError(error: Error) {
    throw error;
  }
}
