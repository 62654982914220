import { Injectable } from '@angular/core';
import { collection, doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { UploadTaskSnapshot } from '@angular/fire/storage';
import { WebRTCStats } from '@voxeet/voxeet-web-sdk/types/models/Statistics';
import { BehaviorSubject, Subject, SubjectLike, throttleTime } from 'rxjs';
import { RecordingInfo, RecordingStats } from '@sc/types';
import { StatsType } from '@sc/types';
import { SessionStats } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  defaultConnectionStats: SessionStats = {
    lastUpdated: new Date().getTime(),
    audioOnly: false,
    network: {},
    remote: {},
    audio: {},
    video: {},
  };
  defaultRecordingStats: RecordingStats = {
    chunkQueueSize: 0,
    totalChunks: 0,
    bytesRecorded: 0,
    bytesTransferred: 0,
    timestamp: new Date().getTime(),
  };
  sessionsCol = collection(this.firestore, 'sessions');

  studioSession$ = this.sessionsService.studioSession$;
  connectionStats$ = new BehaviorSubject<SessionStats>({ ...this.defaultConnectionStats });
  recordingStats$ = new BehaviorSubject<Record<StatsType, RecordingStats>>({
    audio: { ...this.defaultRecordingStats },
    video: { ...this.defaultRecordingStats },
    screen: { ...this.defaultRecordingStats },
  });

  private throttledUpdatesMap: Map<
    StatsType,
    Subject<{ recordingID: string; stats: RecordingStats; sessionID?: string }>
  > = new Map();

  constructor(private firestore: Firestore, private sessionsService: SessionsService) {}

  resetConnectionStats() {
    this.connectionStats$.next({ ...this.defaultConnectionStats });
  }

  async parseConnectionStats(dolbyStats: WebRTCStats | any, participantID: string, saveStats = false) {
    // console.log('RAW STATS: ', dolbyStats);
    if (!dolbyStats) return;
    const stats: SessionStats = this.connectionStats$.value;

    const copyPropsToStat = (from: any, props: Set<string>, subprop?: string) => {
      if (props.has('timestamp') && !from.timestamp) {
        from.timestamp = new Date().getTime();
      }

      if (!stats[from.kind]) stats[from.kind] = {};
      if (!stats[from.kind][from.ssrc]) stats[from.kind][from.ssrc] = {};
      if (subprop && !stats[from.kind][from.ssrc][subprop]) stats[from.kind][from.ssrc][subprop] = {};

      const statRef = subprop ? stats[from.kind][from.ssrc][subprop] : stats[from.kind][from.ssrc];

      if (statRef.timestamp) statRef.lastTime = statRef.timestamp;
      if (statRef.bytesSent >= 0) statRef.lastBytesSent = statRef.bytesSent;

      props.forEach((stat) => {
        if (from[stat] !== undefined) statRef[stat] = from[stat];
      });
      if (statRef.bytesSent) statRef.keep = true;
      return statRef;
    };

    // const partID = VoxeetSDK.session.participant.id;
    let localStats: Array<any> = [];
    dolbyStats.forEach((v, k) => {
      if (k === participantID) localStats = v;
      else {
        if (!stats.remote[k]) stats.remote[k] = {};
        const { audio, video } = v.find((stat) => stat.id === 'Scores');
        stats.remote[k].scores = { audio, video };
      }
    });

    Object.keys(stats.audio).forEach((ssrc) => {
      stats.audio[ssrc].keep = false;
    });
    Object.keys(stats.video).forEach((ssrc) => {
      stats.video[ssrc].keep = false;
    });

    localStats.forEach((statDetail) => {
      if (!statDetail.kind && statDetail.mediaType) statDetail.kind = statDetail.mediaType;
      if (statDetail.kind && !stats[statDetail.kind]) stats[statDetail.kind] = {};
      if (statDetail.type === 'candidate-pair') {
        if (statDetail.nominated && statDetail.state === 'succeeded' && statDetail.selected !== false) {
          const { availableOutgoingBitrate, currentRoundTripTime, totalRoundTripTime, timestamp } = statDetail;
          if (
            (stats.network.availableOutgoingBitrate && !availableOutgoingBitrate) ||
            statDetail.transportId.includes('Tdata')
          )
            return;

          if (availableOutgoingBitrate) stats.network.availableOutgoingBitrate = availableOutgoingBitrate;
          if (currentRoundTripTime) stats.network.currentRoundTripTime = currentRoundTripTime;
          if (totalRoundTripTime) stats.network.totalRoundTripTime = totalRoundTripTime;
          if (timestamp) stats.network.timestamp = timestamp;

          if (!stats.network.data) stats.network.data = [];
          stats.network.data.push({ x: stats.network.timestamp, available: availableOutgoingBitrate });
          if (stats.network.data.length > 20) stats.network.data.shift();
        }
      } else if (statDetail.type === 'outbound-rtp') {
        // SafariVideo and ChromeAudio have had bitrateSent with Simulcast, kinda flakey
        // FirefoxAudio missing remoteId only in Classic conf
        // SafariAudio missing remoteId only in Voice conf
        // FirefoxVideo missing framesPerSecond, keyFramesEncoded, mediaSourceId, qualityLimitationResolutionChanges, totalPacketSendDelay, trackId, transportId
        // Only ChromeVideo has qualityLimitationDurations, mid, targetBitrate, qualityLimitationReason, active
        // Only ChromeVideo Simulcast has rid and encoderImplementation

        const orSet = new Set([
          'active',
          'bytesSent',
          'contentType',
          'timestamp',
          'rid',
          'mid',
          'frameWidth',
          'frameHeight',
          'framesPerSecond',
          'qualityLimitationDurations',
          'qualityLimitationReason',
          'targetBitrate',
        ]);

        const orRef = copyPropsToStat(statDetail, orSet);

        let bytes = statDetail.bytesSent - orRef.lastBytesSent;
        if (bytes < 0) bytes = 0;
        const seconds = (statDetail.timestamp - orRef.lastTime) / 1000;
        const byterate = bytes / seconds || 0;
        const bitrate = byterate * 8;
        orRef.bitrate = bitrate > 0 ? Math.round(bitrate) : 0;

        if (!orRef.data) orRef.data = [];
        orRef.data.push({ x: orRef.timestamp, bitrate: Math.round(bitrate) });
        if (orRef.data.length > 20) orRef.data.shift();
        if (orRef.contentType === 'screenshare' && statDetail.bytesSent === orRef.lastBytesSent) orRef.active = false;
      } else if (statDetail.type === 'remote-inbound-rtp') {
        const rirSet = new Set(['kind', 'ssrc', 'timestamp', 'jitter', 'packetsLost', 'packetLost', 'roundTripTime']);
        const remoteRef = stats[statDetail.kind][statDetail.ssrc].remote;
        const lastPL = remoteRef?.packetLost || remoteRef?.packetsLost;
        const rirRef = copyPropsToStat(statDetail, rirSet, 'remote');

        const data: any = { x: rirRef.timestamp, jitter: Math.round(rirRef.jitter * 10000) / 10000 };
        const pl = rirRef.packetLost || rirRef.packetsLost;
        if (pl > lastPL) data.packetsLost = pl - lastPL;

        if (!rirRef.data) rirRef.data = [];
        rirRef.data.push(data);
        if (rirRef.data.length > 20) rirRef.data.shift();
      } else {
        if (statDetail.id === 'Scores') stats.scores = { audio: statDetail.audio, video: statDetail.video };
      }
    });

    stats.lastUpdated = new Date().getTime();

    Object.keys(stats.audio).forEach((ssrc) => {
      if (!stats.audio[ssrc].keep) delete stats.audio[ssrc];
    });
    Object.keys(stats.video).forEach((ssrc) => {
      if (!stats.video[ssrc].keep) delete stats.video[ssrc];
    });

    if (saveStats) this.sessionsService.updateSessionStats(stats);
    this.connectionStats$.next(stats);
    // console.log('STATS', stats);
  }

  statsNewChunk(
    recInfo: RecordingInfo,
    recordingID: string,
    type: StatsType,
    chunkNum: number,
    blob: Blob,
    skipStatUpload = false
  ) {
    recInfo.queue.set(chunkNum, 0);

    const stats = recInfo.stats[type];
    stats.chunkQueueSize = recInfo.queue.size;
    stats.totalChunks = chunkNum;
    if (!stats.chunkMap) stats.chunkMap = {};
    stats.chunkMap[chunkNum] = { size: blob.size, timestamp: new Date().getTime() };

    if (!skipStatUpload) this.updateRecordingStats(recordingID, type, stats, recInfo.sessionID);
  }

  statsStartUpload(recInfo: RecordingInfo, type: StatsType, chunkNum: number, blob: Blob) {
    recInfo.queue.set(chunkNum, 1);

    recInfo.lastBytesTransferred = 0;
    recInfo.stats[type].timestamp = new Date().getTime();
    recInfo.stats[type].bytesRecorded += blob.size;
    recInfo.stats[type].chunkMap[chunkNum].uploadStart = recInfo.stats[type].timestamp;
  }

  statsChunkProgress(
    recInfo: RecordingInfo,
    recordingID: string,
    type: StatsType,
    chunkNum: number,
    snapshot: UploadTaskSnapshot,
    update$: SubjectLike<any>
  ) {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

    recInfo.queue.set(chunkNum, progress);

    recInfo.stats[type].bytesTransferred += snapshot.bytesTransferred - recInfo.lastBytesTransferred;
    recInfo.lastBytesTransferred = snapshot.bytesTransferred;
    recInfo.stats[type].timestamp = new Date().getTime();

    if (progress < 100) update$.next(recordingID);
  }

  statsChunkError(
    recInfo: RecordingInfo,
    recordingID: string,
    type: StatsType,
    chunkNum: number,
    update$: SubjectLike<any>
  ) {
    recInfo.stats[type].chunkQueueSize = recInfo.queue.size;
    recInfo.stats[type].timestamp = new Date().getTime();

    update$.next(recordingID);
  }

  statsChunkComplete(
    recInfo: RecordingInfo,
    recordingID: string,
    type: StatsType,
    chunkNum: number,
    t0: number,
    update$: SubjectLike<any>,
    skipStatUpload = false
  ) {
    recInfo.stats[type].chunkMap[chunkNum].uploadTime = performance.now() - t0;
    recInfo.stats[type].timestamp = new Date().getTime();
    recInfo.stats[type].chunkQueueSize = recInfo.queue.size;
    recInfo.queue.delete(chunkNum);

    update$.next(recordingID);

    if (!skipStatUpload) this.updateRecordingStats(recordingID, type, recInfo.stats[type], recInfo.sessionID);
  }

  getRecordingStats(recordingID: string, type: StatsType, sessionID?: string) {
    if (!sessionID)
      sessionID = this.studioSession$.value?.sessionID
        ? this.studioSession$.value?.sessionID
        : this.sessionsService.lastSession.sessionID;
    const statsRef = doc(this.sessionsCol, sessionID, 'recordings', recordingID, 'stats', type);
    return docData(statsRef, { idField: 'recordingID' });
  }

  updateRecordingStats(recordingID: string, type: StatsType, stats: RecordingStats, sessionID?: string) {
    if (!sessionID) sessionID = this.studioSession$.value.sessionID;
    this.recordingStats$.next({ ...this.recordingStats$.value, [type]: stats });
    if (!this.throttledUpdatesMap.has(type)) {
      const subject = new Subject<{ recordingID: string; stats: RecordingStats; sessionID: string }>();
      this.throttledUpdatesMap.set(type, subject);
      subject.pipe(throttleTime(3000)).subscribe(({ recordingID, stats, sessionID }) => {
        this.updateRecordingStatsThrottled(recordingID, type, stats, sessionID);
      });
    }
    this.throttledUpdatesMap.get(type).next({ recordingID, stats, sessionID });
  }

  updateRecordingStatsThrottled(recordingID: string, type: StatsType, stats: RecordingStats, sessionID?: string) {
    const statsRef = doc(this.sessionsCol, sessionID, 'recordings', recordingID, 'stats', type);
    let updatedChunkMap;

    if (stats.chunkMap) {
      const keys = Object.keys(stats.chunkMap);
      if (keys.length > 10) {
        updatedChunkMap = { ...stats.chunkMap };
        const toDelete = keys.length - 10;
        for (let i = 0; i < toDelete; i++) {
          delete updatedChunkMap[keys[i]];
        }
      }
    }

    const updatedStats = updatedChunkMap ? { ...stats, chunkMap: updatedChunkMap } : { ...stats };
    return setDoc(statsRef, updatedStats, { merge: true });
  }
}
