/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { Analytics, logEvent, setUserId, setUserProperties } from '@angular/fire/analytics';
import * as dayjs from 'dayjs';
import * as dayjsDuration from 'dayjs/plugin/duration';
dayjs.extend(dayjsDuration);

import { UserService } from '../user/user.service';
import { UserModel } from '@sc/types';
import { WalletService } from '../wallet/wallet.service';
import { environment } from '../../../environments/environment';
import { APP_VERSION } from '../../../environments/app.version';
import { CanceledService } from '../canceled/canceled.service';
import { CookiesService } from '../cookies/cookies.service';
import { FilenameService } from '../filename/filename.service';
import { MuteService } from '../mute/mute.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { RolesService } from '../roles/roles.service';
import { SessionsService } from '../sessions/sessions.service';
import { SettingsService } from '../settings/settings.service';
import { ShowsService } from '../shows/shows.service';
import { TrialService } from '../trial/trial.service';
import { VideoOffService } from '../video-off/video-off.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  user$ = this.userService.activeUser$;
  lastNavPath: string;
  navTime: number;

  constructor(
    private analytics: Analytics,
    private canceledService: CanceledService,
    private cookiesService: CookiesService,
    private filenameService: FilenameService,
    private muteService: MuteService,
    private organizationsService: OrganizationsService,
    private rolesService: RolesService,
    private router: Router,
    private sessionsService: SessionsService,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private trialService: TrialService,
    private userService: UserService,
    private videoOffService: VideoOffService,
    private walletService: WalletService
  ) {
    this.setupUser();
    this.setupTimeOnPage();
  }

  init(userToken: string) {
    if (!this.analytics) return;
    setUserId(this.analytics, userToken);
  }

  track(event: string, properties: any = {}) {
    if (!this.analytics) return;
    properties = this.addSuperProperties(properties);

    // console.log('EVENT: ', event, properties);

    return logEvent(this.analytics, event, properties);
  }

  addSuperProperties(properties: any) {
    properties.version = APP_VERSION.packageVersion;
    properties.production = environment.production;
    properties.beta = environment.beta;
    properties.staging = environment.staging;
    properties.commitSha = APP_VERSION.commitSha;
    properties.branch = APP_VERSION.branch;

    properties.lastNavPath = this.lastNavPath;
    properties.timeOnPage = +(performance.now() - this.navTime).toFixed(2) || 0;

    // properties.audioRecorderState = this.audioRecorderService?.recorder?.state;
    // properties.audioRecorderAudioBitsPerSecond = this.audioRecorderService?.recorder?.audioBitsPerSecond;

    if (!properties.daysLeftBeforeCancelledAccountDeletion)
      properties.daysLeft = this.canceledService.canceledStatus$.value?.daysLeft;
    if (!properties.canceled) properties.canceled = this.canceledService.canceledStatus$.value?.canceled;

    if (!properties.utm_source) properties.utm_source = this.cookiesService.get('utm_source');
    if (!properties.utm_medium) properties.utm_medium = this.cookiesService.get('utm_medium');
    if (!properties.utm_campaign) properties.utm_campaign = this.cookiesService.get('utm_campaign');
    if (!properties.utm_term) properties.utm_term = this.cookiesService.get('utm_term');
    if (!properties.utm_content) properties.utm_content = this.cookiesService.get('utm_content');
    if (!properties.utm_gclid) properties.utm_gclid = this.cookiesService.get('utm_gclid');
    if (!properties.sc_coupon) properties.sc_coupon = this.cookiesService.get('sc_coupon');
    if (!properties.sc_plan) properties.sc_plan = this.cookiesService.get('sc_plan');

    // properties.microphone = this.equipmentService.selectedMicrophone$.value?.label;
    // properties.headphones = this.equipmentService.selectedHeadphones$.value?.label;
    // properties.camera = this.equipmentService.selectedCamera$.value?.label;
    // properties.mediaConstraints = JSON.stringify(this.equipmentService.constraints$.value);
    // properties.echoCancellation = this.equipmentService.echoCancellation$.value;

    if (!properties.fileName) properties.fileName = this.filenameService.fileName$.value;

    if (!properties.mute) properties.mute = this.muteService.mute$.value;

    if (!properties.orgID) properties.orgID = this.organizationsService.dashboardOrg$.value?.orgID;
    if (!properties.orgName) properties.orgName = this.organizationsService.dashboardOrg$.value?.orgName;
    if (!properties.orgOwner) properties.orgOwner = this.organizationsService.dashboardOrg$.value?.orgOwner;
    if (!properties.billingEmail) properties.billingEmail = this.organizationsService.dashboardOrg$.value?.billingEmail;
    if (!properties.orgRole) properties.orgRole = this.organizationsService.orgRole$.value;

    if (!properties.studioOrgID) properties.orgID = this.sessionsService.studioOrg$.value?.orgID;
    if (!properties.studioOrgName) properties.orgName = this.sessionsService.studioOrg$.value?.orgName;
    if (!properties.studioOrgOwner) properties.orgOwner = this.sessionsService.studioOrg$.value?.orgOwner;

    // properties.recording = this.recordingService.recording$.value;
    // properties.recordingID = this.recordingService.activeRecording$.value?.recordingID;
    // properties.timer = dayjs.duration(this.recordingService.timer$.value || 0, 'seconds').format('HH:mm:ss');

    if (!properties.role) properties.role = this.rolesService.role$.value;

    // properties.screenRecorderState = this.screenRecorderService?.recorder?.state;
    // properties.screenRecorderVideoBitsPerSecond = this.screenRecorderService?.recorder?.videoBitsPerSecond;
    // properties.screenFrameRate = this.screenRecorderService?.screen?.getTracks()[0]?.getSettings().frameRate;
    // properties.screenResolution = this.screenRecorderService?.screen?.getTracks()[0]?.getSettings().height;

    if (!properties.sessionTitle) properties.sessionTitle = this.sessionsService.studioSession$.value?.sessionTitle;
    if (!properties.showTitle) properties.showTitle = this.sessionsService.studioSession$.value?.showTitle;
    if (!properties.startTimestamp)
      properties.startTimestamp = this.sessionsService.studioSession$.value?.startTimestamp;
    if (!properties.startTime) properties.startTime = this.sessionsService.studioSession$.value?.startTime;
    if (!properties.endTime) properties.endTime = this.sessionsService.studioSession$.value?.endTime;
    if (!properties.videoEnabled) properties.videoEnabled = this.sessionsService.studioSession$.value?.videoEnabled;
    if (!properties.sessionID) properties.sessionID = this.sessionsService.studioSession$.value?.sessionID;

    if (!properties.userAppSettingsTheme)
      properties.userAppSettingsTheme = this.settingsService.userAppSettings$.value?.theme;
    if (!properties.userAppSettingsMuteOnJoin)
      properties.userAppSettingsMuteOnJoin = this.settingsService.userAppSettings$.value?.muteOnJoin;
    if (!properties.activeShowSettingsFullHD)
      properties.activeShowSettingsFullHD = this.settingsService.studioShowSettings$.value?.fullHD;
    if (!properties.activeShowSettingsVoiceConference)
      properties.activeShowSettingsVoiceConference = this.settingsService.studioShowSettings$.value?.voiceConference;

    if (!properties.showID) properties.showID = this.showsService.dashboardShow$.value?.showID;
    if (!properties.showName) properties.showName = this.showsService.dashboardShow$.value?.showName;
    if (!properties.showOwner) properties.showOwner = this.showsService.dashboardShow$.value?.showOwner;

    if (!properties.daysLeftOnTrial) properties.daysLeftOnTrial = this.trialService.trialStatus$.value?.daysLeft;
    if (!properties.onTrial) properties.onTrial = this.trialService.trialStatus$.value?.onTrial;

    if (!properties.uid) properties.uid = this.user$.value?.uid;
    if (!properties.recordingsTake) properties.recordingsTake = this.user$.value?.activeRecordingsTake;
    if (!properties.displayName) properties.displayName = this.user$.value?.displayName;
    if (!properties.email) properties.email = this.user$.value?.email;
    if (!properties.fullName) properties.fullName = this.user$.value?.fullName;
    if (!properties.creationTime) properties.creationTime = this.user$.value?.creationTime;
    if (!properties.lastSignInTime) properties.lastSignInTime = this.user$.value?.lastSignInTime;

    if (!properties.videoOff) properties.videoOff = this.videoOffService.videoOff$.value;

    // properties.videoRecorderState = this.videoRecorderService?.recorder?.state;
    // properties.videoRecorderVideoBitsPerSecond = this.videoRecorderService?.recorder?.videoBitsPerSecond;
    // properties.videoRecorderAudioBitsPerSecond = this.videoRecorderService?.recorder?.audioBitsPerSecond;

    if (!properties.planID) properties.planID = this.walletService.dashboardPlan$.value?.id;
    if (!properties.studioPlan) properties.studioPlan = this.walletService.studioPlan$.value?.id;
    if (!properties.customerID) properties.customerID = this.walletService.customerID$.value;
    if (!properties.usedRecordingTime) properties.usedRecordingTime = this.walletService.wallet$.value?.used;
    if (!properties.availableRecordingTime)
      properties.availableRecordingTime = this.walletService.wallet$.value?.available;
    if (!properties.overdrawnLedger) properties.overdrawnLedger = this.walletService.wallet$.value?.overdrawn;
    if (!properties.accountFrozen) properties.accountFrozen = this.walletService.wallet$.value?.frozen;
    if (!properties.accountStatus) properties.accountStatus = this.walletService.wallet$.value?.status;

    if (!properties.descriptDriveID)
      properties.descriptDriveID = this.organizationsService.dashboardOrg$.value?.driveID;

    this.walletService.customer$.value?.subscriptions?.data.map((stripeSubscription, index) => {
      properties[`stripeSubscriptionID${index}`] = stripeSubscription.id;
      properties[`stripeSubscriptionStartDate${index}`] = stripeSubscription.start_date;
    });

    for (const key in properties) {
      if (Object.prototype.hasOwnProperty.call(properties, key)) {
        const value = properties[key];
        if (value === undefined || value === null || value === '') delete properties[key];
      }
    }

    return properties;
  }

  alias(userToken: string) {}

  setupUser() {
    this.userService.activeUser$.toPromise().then((user: UserModel) => {
      this.setupCustomerID();
      this.init(this.user$.value.uid);
      const props = {
        // firebaseID: this.user.uid, set automatically
        name: this.user$.value.displayName,
        guest: this.user$.value.guest,
      };
      const orgID = this.organizationsService.dashboardOrgID$.value;
      if (orgID) props['organization'] = orgID;

      setUserProperties(this.analytics, props);
    });
  }

  setupCustomerID() {
    this.walletService.customerID$.subscribe((id) => {
      if (!id) {
        return;
      }

      setUserProperties(this.analytics, { stripeID: id });
    });
  }

  setupTimeOnPage() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) return;
      this.lastNavPath = event.url;
      this.navTime = performance.now();
    });
  }

  reset() {
    if (!this.user$.value) return;
    setUserId(this.analytics, '');
    setUserId(this.analytics, this.user$.value.uid);
  }
}
