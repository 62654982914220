<h4>Advanced Show Settings</h4>
<ion-list *ngIf="settings">
  <!-- <ion-item aria-label="Default Conference Type" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Default Conference Type </strong>
        <sc-learn-more topic="confTypes"></sc-learn-more>
      </span>
      <p>
        This setting does <b>not</b> impact you or your guest’s Primary HD Recordings. The conference references the
        realtime connection in the studio and any processing will apply to cloud recordings only.

        <strong>Please note that some of these settings will be leaving in the near future!</strong>
      </p>
      <div class="conf-cards">
        <ion-button
        aria-label="Set Default Conference Type to Daily"
        class="conf-card"
        fill="clear"
        (click)="setConfType(ConfTypes.DAILY)"
        [class.active]="settings.defaultConfType === ConfTypes.DAILY">
        <div class="conf-card-content">
          <div class="card-content-header">
            <ion-icon src="assets/icons/24px/dailyco-logo.svg"></ion-icon>
            <h5>Daily.co</h5>
          </div>
          <ul>
            <li>Individual Track Backups </li>
            <li>Adaptive Bitrate ( 700kbps | 320kbps )</li>
            <li>No Processing</li>
          </ul>
        </div>
      </ion-button>
        <ion-button
          aria-label="Set Default Conference Type to Classic"
          class="conf-card"
          fill="clear"
          (click)="setConfType(ConfTypes.CLASSIC)"
          [class.active]="!settings.defaultConfType || settings.defaultConfType === ConfTypes.CLASSIC">
          <div class="conf-card-content">
            <div class="card-content-header">
              <ion-icon src="assets/icons/24px/optimize-audio.svg"></ion-icon>
              <h5>Classic Conference</h5>
            </div>
            <ul>
              <li>Low Latency Connection</li>
              <li>High Audio Quality (128kbps | 48khz)</li>
              <li>No Processing</li>
            </ul>
          </div>
        </ion-button>
        <ion-button
          aria-label="Set Default Conference Type to Voice"
          class="conf-card"
          fill="clear"
          (click)="setConfType(ConfTypes.VOICE)"
          [class.active]="settings.defaultConfType === ConfTypes.VOICE">
          <div class="conf-card-content">
            <div class="card-content-header">
              <ion-icon src="assets/icons/24px/dolby-voice-2.svg"></ion-icon>
              <h5>Dolby Voice Conference</h5>
            </div>
            <ul>
              <li>Realtime Mastering / Noise Reduction</li>
              <li>Lower Audio Quality (42kbps | 48khz)</li>
              <li>Individual Track Cloud Backups</li>
            </ul>
          </div>
        </ion-button>
      </div>
    </ion-label>
  </ion-item> -->

  <ion-item aria-label="Default Session Quality" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Default Session Quality </strong>
        <sc-learn-more topic="fullHD"></sc-learn-more>
      </span>

      <p>
        Enables maximum possible video quality for all participants in all sessions created. 
        Session Quality settings can also be manually set within a Session. When enabled, the Session will set each participants quality to their Network and Equipment Maximum.
        Max Quality is 4K (2160p) with PCM Audio Enabled. 
      </p>
    </ion-label>

    <ion-toggle [checked]="settings.maxQuality" (ionChange)="toggleDefaultSessionQuality($event)" color="primary" mode="ios" slot="end">
    </ion-toggle>
  </ion-item>

  <!-- <ion-item aria-label="Toggle Notify When Backstage Participant Joins" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Backstage Participant Join Notification </strong>
        <sc-learn-more topic="backstageJoin"></sc-learn-more>
      </span>
      <p>Receive notifications when someone joins backstage.</p>
    </ion-label>

    <ion-toggle
      [checked]="settings.showToastOnBackstageJoin !== false"
      (ionChange)="toggleNotifyBackstage($event)"
      color="primary"
      mode="ios"
      slot="end">
    </ion-toggle>
  </ion-item> -->

  <ion-item aria-label="Toggle Recording Countdown" lines="full" class="recording-countdown-slider">
    <div>
      <ion-label color="lightest" class="ion-text-wrap">
        <span class="label-header">
          <strong>Recording Start Countdown <span *ngIf="settings.recordingCountdown === 10">🚀</span></strong>
          <sc-learn-more topic="recordingCountdown"></sc-learn-more>
        </span>

        <p>
          Display a countdown to notify participants that recording is about to start. Select 0 if you want the
          recording to start immediately.
        </p>
      </ion-label>

      <ion-range
        [value]="settings.recordingCountdown ?? 0"
        [min]="0"
        [max]="10"
        [pin]="true"
        [ticks]="true"
        [snaps]="true"
        (ionChange)="changeRecordingCountdown($event)"></ion-range>
    </div>
  </ion-item>

  <ion-item aria-label="Mute Conference Join/Leave Tone" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Mute Conference Join/Leave Tone </strong>
        <sc-learn-more topic="joinLeaveTone"></sc-learn-more>
      </span>
      <p>Mute audio tone when someone joins or leaves the main stage for everyone in the session.</p>
    </ion-label>

    <ion-toggle
      [checked]="settings.muteConfAudioNotifications"
      (ionChange)="toggleMuteConfAudioNotifications($event)"
      color="primary"
      mode="ios"
      slot="end">
    </ion-toggle>
  </ion-item>

  <ion-item aria-label="Enable Chat in Show Sessions" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Enable Chat in Show Sessions </strong>
        <sc-learn-more topic="enableChatInShow"></sc-learn-more>
      </span>
      <p>
        Enable or Disable Chat in Show Sessions. This will be respected across all sessions for this particular show. (On by Default).
      </p>
    </ion-label>

    <ion-toggle
      [checked]="settings?.enableChat || (settings?.enableChat === undefined) === true"
      (ionChange)="toggleChatInShow($event)"
      color="primary"
      mode="ios"
      slot="end">
    </ion-toggle>
  </ion-item>

  <ion-item aria-label="Enable Recording Start/Stop Sound Effects" lines="full">
    <ion-label color="lightest" class="ion-text-wrap">
      <span class="label-header">
        <strong> Enable Recording Start/Stop Audio Tone </strong>
        <sc-learn-more topic="recordingAudioTone"></sc-learn-more>
      </span>
      <p>Play an audio tone to alert all participants that the recording has started or stopped.</p>
    </ion-label>

    <span class="recording-tone-select" slot="end">
      <ng-select
        [(ngModel)]="selectedRecordingTone"
        (change)="changeRecordingTone($event)"
        [clearable]="false"
        [searchable]="false">
        <ng-option [value]="RecordingTones.NONE">None</ng-option>
        <ng-option [value]="RecordingTones.TONE_1">Audio Tone 1</ng-option>
        <ng-option [value]="RecordingTones.TONE_2">Audio Tone 2</ng-option>
        <ng-option [value]="RecordingTones.SCREEN_READER">Screen Reader</ng-option>
      </ng-select>
      <ion-button aria-label="Preview Tones" *ngIf="selectedRecordingTone" (click)="playRecordingTone()" fill="outline">
        <ion-icon src="assets/icons/32px/play-solid-bold.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </span>
  </ion-item>



  <sc-dolby-settings></sc-dolby-settings>

  <sc-auphonic-settings></sc-auphonic-settings>

  <h5>Danger Zone</h5>

  <ion-row>
    <ion-col size-xs="12" size-sm="12" offset-md="1" size-md="10" offset-lg="2" size-lg="8" offset-xl="3" size-xl="6">
      <ion-button (click)="openDeleteShowModal()" expand="block" id="delete-show-button"> Delete Show </ion-button>
    </ion-col>
  </ion-row>
</ion-list>
<ion-button (click)="close()" size="small" class="text close-button"> Close </ion-button>
