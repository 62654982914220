import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { FeatureFlags } from '@sc/types';
import { SCSubject } from '../../util/sc-subject.class';
import {
  DocumentReference,
  Firestore,
  collection,
  doc,
  docData,
  CollectionReference,
  collectionData,
} from '@angular/fire/firestore';
import { switchMap, Observable, firstValueFrom } from 'rxjs';
import { OrganizationsService } from '../organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public featureFlags$ = new SCSubject<FeatureFlags[]>();
  public activeUser$ = this.userService.activeUser$;
  public dashboardOrgID$ = this.organizationsService.dashboardOrgID$;

  private featureFlagCol: CollectionReference;

  constructor(
    private firestore: Firestore,
    private userService: UserService,
    private organizationsService: OrganizationsService
  ) {
    this.featureFlagCol = collection(this.firestore, 'featureFlags');
    this.setupFeatureFlags();
  }

  setupFeatureFlags() {
    this.dashboardOrgID$
      .pipe(
        switchMap((org) => {
          return collectionData(this.featureFlagCol, { idField: 'id' }) as Observable<FeatureFlags[]>;
        })
      )
      .subscribe({
        next: (featureFlags) => {
          this.featureFlags$.next(featureFlags);
        },
        error: (err) => {
          console.error('Error fetching feature flags:', err);
        },
        complete: () => {
          console.log('Feature flags setup complete');
        },
      });
  }
  getFeatureFlags(): Observable<FeatureFlags[]> {
    return this.featureFlags$.asObservable();
  }

  getFeatureFlag(id: string): FeatureFlags {
    return this.featureFlags$.value.find((featureFlag) => featureFlag.id === id);
  }
}
