import { Component, Inject, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { WindowToken } from '../../services/window/window';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { FileService } from '../../services/file/file.service';
import { IdTokenService } from '../../services/id-token/id-token.service';
import { Recording } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { Session } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { ShowsService } from '../../services/shows/shows.service';
import { RecordingService } from '../../services/recording/recording.service';
import { RecordingsService } from '../../services/recordings/recordings.service';

@Component({
  selector: 'sc-delete-recording',
  templateUrl: 'delete-recording.page.html',
  styleUrls: ['delete-recording.page.scss'],
})
export class DeleteRecordingPage {
  @Input() recording: Recording;
  memberUID: string;
  startTime: any;
  subs: Array<Subscription> = [];
  showID: any;
  activeUser$ = this.usersService.activeUser$;
  guest: any;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private toastrService: ToastrService,
    private usersService: UserService,
    private analyticsService: AnalyticsService,
    private recordingsService: RecordingsService,
    private fileService: FileService,
    private idTokenService: IdTokenService,
    @Inject(WindowToken) private window: Window
  ) {}

  async cancel() {
    let modal = await this.modalController.getTop();
    if (modal) {
      await this.modalController.dismiss({ id: modal.id });
    }
    await this.analyticsService.track('cancelled delete recording', {
      fileName: this.recording.fileName,
      recordingID: this.recording.recordingID,
      showID: this.recording.showID,
      sessionID: this.recording.sessionID,
    });
  }

  async deleteRecording() {
    try {
      const idToken = await this.idTokenService.getFreshIdToken();
      await this.fileService.deleteFile(this.recording.showID, this.recording.sessionID, this.recording, idToken);
      this.recordingsService.removeRecordingFromTake(this.recording.recordingID);
      if (
        this.recordingsService.selectedRecordings$.value.some(
          (recording) => recording.recordingID === this.recording.recordingID
        )
      ) {
        this.recordingsService.removeSelectedRecording(this.recording);
      }
      this.toastrService.success(null, `Successfully deleted Recording`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.analyticsService.track('deleted recording', {
        fileName: this.recording.fileName,
        recordingID: this.recording.recordingID,
        showID: this.recording.showID,
        sessionID: this.recording.sessionID,
        success: true,
      });
      let modal = await this.modalController.getTop();
      if (modal) {
        await this.modalController.dismiss({ id: modal.id });
      }
      if (this.window.location.href.indexOf('studio') > -1) {
        if (modal) {
          await this.modalController.dismiss({ id: modal.id });
        }
      }
    } catch (error) {
      if (error === 'overlay does not exist') return;

      this.toastrService.error(null, `Failed to delete Recording`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      await this.analyticsService.track('deleted recording', {
        fileName: this.recording.fileName,
        recordingID: this.recording.recordingID,
        showID: this.recording.showID,
        sessionID: this.recording.sessionID,
        success: false,
      });
    }

    if (this.window.location.href.indexOf('studio') > -1) {
      let modal = await this.modalController.getTop();
      if (modal) {
        await this.modalController.dismiss();
        await this.popoverController.dismiss();
      }
    }
  }
}
