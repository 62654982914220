import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClosedService } from '../services/closed/closed.service';

import { GuardService } from '../services/guard/guard.service';
import { SquadCasterService } from '../services/squadcaster/squadcaster.service';
import { environment } from '../../environments/environment';
import { canDeactivatePageGuard } from '../guards/CanDeactivate';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'sessions',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountPageModule),
    canActivate: [SquadCasterService],
  },
  // {
  //   path: 'checkout',
  //   loadChildren: () => import('./checkout/checkout.module').then((m) => m.CheckoutPageModule),
  //   canActivate: [SquadCasterService, ClosedService],
  // },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [SquadCasterService, ClosedService],
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then((m) => m.SearchPageModule),
    canActivate: [SquadCasterService, ClosedService],
  },
  {
    path: 'design',
    loadChildren: () => import('./design/design.module').then((m) => m.DesignPageModule),
    canActivate: [SquadCasterService],
  },
  {
    path: 'notifications-edit',
    loadChildren: () => import('./notifications/notifications-edit.module').then((m) => m.NotificationsEditPageModule),
    canActivate: [SquadCasterService],
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
    canActivate: [() => !environment.production],
  },
  {
    path: 'studio',
    loadChildren: () => import('./studio/studio.module').then((m) => m.StudioPageModule),
    canDeactivate: [() => canDeactivatePageGuard()],
  },
  {
    path: 'descript-info',
    loadChildren: () => import('./descript-info/descript-info.module').then((m) => m.DescriptInfoPageModule),
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  { path: '**', redirectTo: '/auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class SquadCastRoutesModule {}
