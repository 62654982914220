import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Firestore,
  collection,
  doc,
  docData,
  CollectionReference,
  query,
  where,
  setDoc,
} from '@angular/fire/firestore';

import { environment } from '../../../environments/environment';
import { combineLatest, firstValueFrom, lastValueFrom, of, switchMap } from 'rxjs';

import { UserService } from '../user/user.service';
import { SCSubject } from '../../util/sc-subject.class';
import {
  DescriptFile,
  DescriptImport,
  DescriptDrive,
  Plan,
  Organization,
  StripeSubscriptionDTO,
  DescriptPlanNames,
  AmplionSubscriptionResponse,
  DescriptLinkResponse,
  SessionDTO,
  WebhookEvent,
  WebhookEventNames,
  Recording,
} from '@sc/types';
import { OrganizationsService } from '../organizations/organizations.service';
import { collectionData } from 'rxfire/firestore';
import { Router } from '@angular/router';
import { CloudFunctionsService } from '../cloud-functions.service';
import { WalletService } from '../wallet/wallet.service';
import { AmplionService } from '../amplion/amplion.service';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ModalController } from '@ionic/angular';
import { DescriptLinkPage } from '../../modals/descript-link/descript-link.page';
import { SessionsService } from '../sessions/sessions.service';
import { ShowsService } from '../shows/shows.service';
import { CalifoneService } from '../califone/califone.service';
import { AnalyticsService } from '../analytics/analytics.service';

import * as Rollbar from 'rollbar';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { FarnsworthService } from '../farnsworth/farnsworth.service';
import { EditInDescriptToastComponent } from '../../toasts/edit-in-descript-toast/edit-in-descript-toast.component';
import { BendixService } from '../bendix/bendix.service';
import { IdTokenService } from '../id-token/id-token.service';
import { RecordingsService } from '../recordings/recordings.service';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

@Injectable({
  providedIn: 'root',
})
export class DescriptService {
  public activeUser$ = this.userService.activeUser$;
  public descriptDriveID$ = new SCSubject<string>();
  public linkInfo$ = new SCSubject<DescriptLinkResponse>(); // Available on page load when a user enters from Descript with a valid link payload
  public descriptDrive$ = new SCSubject<DescriptDrive>(); // Available when the org has been linked
  public linkDrive$ = new SCSubject<DescriptDrive>(); // Available once the user has logged in and has a valid link payload
  public linkingDriveName$ = new SCSubject<string>();
  public descriptPlans$ = new SCSubject<Plan[]>();
  public linkPlan$ = new SCSubject<Plan>();
  public loading$ = new SCSubject<string>(null);

  public claiming = false;

  private dashboardOrg$ = this.organizationsService.dashboardOrg$;
  private dashboardOrgID$ = this.organizationsService.dashboardOrgID$;
  private orgCol = collection(this.firestore, 'organizations') as CollectionReference<Organization>;
  private driveCol = collection(this.firestore, 'descriptDrives') as CollectionReference<DescriptDrive>;
  private plansCol = collection(this.firestore, 'plans') as CollectionReference<Plan>;

  private califone = environment.microservices.califone;

  constructor(
    private amplionService: AmplionService,
    private analyticsService: AnalyticsService,
    private bendixService: BendixService,
    private califoneService: CalifoneService,
    private cfs: CloudFunctionsService,
    private idTokenService: IdTokenService,
    private firestore: Firestore,
    private farnsworthService: FarnsworthService,
    private http: HttpClient,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private router: Router,
    private sessionsService: SessionsService,
    private recordingsService: RecordingsService,
    private showsService: ShowsService,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.setupLinkingDriveName();
    this.setupLink();
    this.setupOrgDriveInfo();
    this.setupLinkDriveInfo();
    this.setupDescriptPlans();
  }

  /**
   * Sets up the Descript link by parsing the URL parameters and sending a request to the backend to verify the link.
   * If the link is valid, it updates the linkInfo$ subject with the link information.
   * If the user is not the owner of the drive and the drive has not been claimed, it navigates to the Descript info page.
   *
   * @returns void
   */
  async setupLink() {
    const url = new URL(window.location.href);
    const driveID = url.searchParams.get('DriveId');
    const isDriveOwner = url.searchParams.get('IsDriveOwner');
    const preApprovedOrgID = url.searchParams.get('PreApprovedOrgId');
    const compositionName = decodeURIComponent(url.searchParams.get('CompositionName'));
    const signature = url.searchParams.get('Signature');
    if (driveID && isDriveOwner && signature) {
      await this.analyticsService.track('descript link received', {
        descriptLinkInfo: { driveID, isDriveOwner, compositionName, preApprovedOrgID },
      });
      this.loading$.next('Redirecting to SquadCast...');
      this.router.navigate([], {
        queryParams: { DriveId: null, IsDriveOwner: null, Signature: null, CompositionName: null },
        queryParamsHandling: 'merge',
      });
      const linkInfo = (await this.cfs
        .postAnon('descript-link', {
          driveID,
          isDriveOwner,
          signature,
          preApprovedOrgID,
          compositionName: compositionName === 'null' ? null : compositionName,
        })
        .catch((e) => {
          this.rollbar.error('Failed to verify descript link parameters', e);
        })) as DescriptLinkResponse;

      if (!linkInfo) {
        this.loading$.next(null);
        return;
      }
      await this.analyticsService.track('descript link confirmed', { descriptLinkInfo: linkInfo });

      // Navigate to info page if the user is not the owner of the drive
      if (linkInfo.isDriveOwner === false && linkInfo.claimed === false) {
        this.router.navigate(['/descript-info'], {
          state: {
            title: 'Must be the Drive owner to connect',
            description: 'Contact your Drive owner to connect to SquadCast',
          },
        });

        const { driveName } = await firstValueFrom(this.getDrive(linkInfo.driveID));
        this.linkingDriveName$.next(driveName);
      } else {
        this.linkInfo$.next(linkInfo);
      }

      this.loading$.next(null);
    }
  }

  /**
   * Sets up the Descript drive information for the organization by subscribing to the dashboard organization ID and getting the organization's drive information.
   * If the organization has a Descript drive, it sets the Descript drive ID.
   * If the Descript drive ID exists, it gets the Descript drive information and updates the descriptDrive$ subject with the drive information.
   *
   * @returns void
   */
  async setupOrgDriveInfo() {
    this.dashboardOrgID$
      .pipe(
        switchMap((orgID) => {
          return this.getOrgDrive(orgID);
        })
      )
      .subscribe(async (driveInfo) => {
        if (!driveInfo.length) {
          this.descriptDrive$.next(null);
          return;
        }
        await this.descriptDriveID$.nextExistingValue();
        const drive = driveInfo.find((drive) => drive.driveID === this.descriptDriveID$.value) as DescriptDrive;
        if (
          drive.driveID === this.descriptDrive$.value?.driveID &&
          drive.lastSynced?.seconds == this.descriptDrive$.value?.lastSynced?.seconds
        ) {
          return;
        }
        if (drive) {
          await this.analyticsService.track('descript drive updated', { descriptDriveInfo: drive });
          this.descriptDrive$.next(drive);
        }
      });

    this.dashboardOrg$.subscribe((org) => {
      if (org.driveID) this.descriptDriveID$.next(org.driveID);
      else this.descriptDriveID$.next(null);
    });
  }

  /**
   * Sets up the Drive information associated with the link parameters
   * If the drive is claimed, send to handler
   * Changes status to STARTED if not claimed
   * If not claimed and has entitlements, select a plan and show link modal
   *
   * @returns void
   */
  async setupLinkDriveInfo() {
    await this.activeUser$.toPromise();
    this.linkInfo$
      .pipe(
        switchMap((linkInfo: DescriptLinkResponse) => {
          if (linkInfo?.driveID) return this.getDrive(linkInfo.driveID);
          else return of(null);
        })
      )
      .subscribe(async (driveInfo) => {
        if (driveInfo) {
          if (driveInfo.orgID && driveInfo.claimed) {
            if (!this.claiming) this.loading$.next('Setting up Session...');
            await this.handleClaimedDrive(driveInfo);
          } else if (driveInfo.linkStatus === 'NOT_STARTED') {
            await this.analyticsService.track('descript drive link started', { descriptDriveInfo: driveInfo });
            await setDoc(doc(this.driveCol, driveInfo.driveID), { linkStatus: 'STARTED' }, { merge: true });
          }

          this.linkDrive$.next(driveInfo);
          if (!driveInfo.claimed && driveInfo.entitlements) this.selectPlan();
        } else this.linkDrive$.next(null);
      });
  }

  /**
   * Tries to grab the drive name from the linkDrive$ or descriptDrive$ subjects.
   *
   * @returns void
   */
  setupLinkingDriveName() {
    combineLatest([this.linkDrive$, this.descriptDrive$]).subscribe(async ([linkDrive, descriptDrive]) => {
      if (linkDrive) this.linkingDriveName$.next(linkDrive.driveName);
      else if (descriptDrive) this.linkingDriveName$.next(descriptDrive.driveName);
    });
  }

  /**
   * Listens to Firestore plans with isDescriptPlan = true and updates descriptPlans$
   *
   * @returns void
   */
  async setupDescriptPlans() {
    await this.activeUser$.toPromise();
    this.getDescriptPlans().subscribe((plans) => {
      this.descriptPlans$.next(plans);
    });
  }

  /**
   * Checks if the user is a member of the organization
   * Switches the dashboard org to the drive org
   * Starts a session if not claiming
   *
   * @param driveInfo : DescriptDrive
   * @returns void
   */
  async handleClaimedDrive(driveInfo: DescriptDrive) {
    const compositionName = this.linkInfo$.value.compositionName;
    this.linkInfo$.next(null);
    // Redirect if no access to org
    const org = this.organizationsService.availableOrgs$.value?.find((org) => org.orgID === driveInfo.orgID);
    if (!org) {
      const wrongOrg = await firstValueFrom(this.organizationsService.getOrg(driveInfo.orgID));

      this.router.navigate(['/descript-info'], {
        state: {
          title: `This Drive is linked to "${wrongOrg.orgName}". You are not a member of "${wrongOrg.orgName}".`,
          description: `Please contact the owner of "${wrongOrg.orgName}" to get added as a member.`,
        },
      });

      this.loading$.next(null);

      return;
    } else if (org.orgID !== this.dashboardOrg$.value.orgID) {
      this.organizationsService.setDashboardOrgID(org.orgID);

      await this.descriptDrive$.nextExistingValue(
        (drive: DescriptDrive) => drive?.orgID && org?.orgID && drive.orgID === org.orgID
      );
    }

    if (!this.claiming) this.startSessionNow(compositionName);
  }

  /**
   * Selects a plan based on the entitlements of the Descript drive and updates the linkPlan$ subject.
   * If the user is not already claiming the drive, it shows the link modal.
   * If the drive has no entitlements, it throws an error.
   *
   * @throws Throws an error if no linkDrive$ is available or if the drive has no entitlements.
   * @returns void
   */
  async selectPlan() {
    if (!this.linkDrive$.value) throw 'Select Plan: No linkDrive$ available';
    if (!this.linkDrive$.value.entitlements) throw 'Select Plan: No entitlements available';

    await this.organizationsService.dashboardOrg$.nextExistingValue();

    const planName: DescriptPlanNames = this.linkDrive$.value.entitlements.type;
    switch (planName) {
      case DescriptPlanNames.CREATOR:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.CREATOR)
        );
        break;
      case DescriptPlanNames.PRO:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.PRO)
        );
        break;
      case DescriptPlanNames.EDU:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.EDU)
        );
        break;
      case DescriptPlanNames.ENTERPRISE:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.ENTERPRISE)
        );
        break;
      case DescriptPlanNames.BUSINESS:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.BUSINESS)
        );
        break;
      default:
        this.linkPlan$.next(
          this.descriptPlans$.value.find((plan) => plan.recommendedDescriptPlan === DescriptPlanNames.FREE)
        );
        break;
    }

    const preApprovedOrgID = this.linkInfo$.value.preApprovedOrgID;
    if (!this.claiming && !preApprovedOrgID) this.showLinkModal();
    else if (!this.claiming && preApprovedOrgID) {
      if (preApprovedOrgID !== this.dashboardOrgID$.value) {
        this.organizationsService.setDashboardOrgID(preApprovedOrgID);

        await this.dashboardOrg$.nextExistingValue((org: Organization) => org.orgID === preApprovedOrgID);
        await this.walletService.refreshStripeCustomer();
      }
      await this.analyticsService.track('descript drive pre approved claim', {
        claimInfo: { orgID: preApprovedOrgID, driveID: this.linkInfo$.value.driveID },
      });
      this.claimDrive();
    }
  }

  /**
   * Shows the link modal.
   * Sets the claiming flag to true.
   *
   * @returns void
   * @throws Throws an error if no linkPlan$ is available.
   */
  async showLinkModal() {
    this.claiming = true;
    const modal = await this.modalController.create({
      component: DescriptLinkPage,
      componentProps: {},

      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      cssClass: 'descript-link-modal',
    });
    modal.present();
    modal.onDidDismiss().then((resp) => {
      // if (resp.data?.cancelled) this.claiming = false; // Only show modal once?
    });
  }

  /**
   * Claims the Descript drive in linkDrive$ and links it to the user's active SquadCast Organization.
   * Will create a new Stripe Customer if none exists.
   * Sets a trial to adjust the billing cycle to match the Descript billing cycle.
   *
   * @throws Throws an error if no link plan is available or if the drive ID is invalid.
   *
   * @returns A promise that resolves when the drive is successfully linked to the user's account.
   */
  async claimDrive() {
    this.claiming = true;
    this.loading$.next('Claiming your Drive...');
    const org = this.dashboardOrg$.value;
    const driveID = this.linkDrive$.value?.driveID;
    const linkPlan = this.linkPlan$.value;
    if (!linkPlan) throw 'No link plan available';
    if (!driveID) throw 'No drive ID available';

    await this.analyticsService.track('descript drive claim initiated', { driveInfo: this.linkDrive$.value });

    await this.checkCustomerExists(org);

    const billingCycleDay = this.linkDrive$.value.entitlements.billingCycleDate;
    let billingDate = dayjs().date(billingCycleDay);
    if (billingDate.isSameOrBefore(dayjs())) billingDate = billingDate.add(1, 'month');

    const newSub: StripeSubscriptionDTO = {
      plan: linkPlan.id,
      trial_end: billingDate.utc().endOf('day').unix(),
      metadata: { orgID: org.orgID },
      proration_behavior: 'none',
    };

    let subResponse: AmplionSubscriptionResponse;
    if (!this.walletService.primarySub$.value) {
      newSub.customer = this.walletService.customerID$.value;
      subResponse = await this.newSubscription(newSub, linkPlan);
    } else {
      subResponse = await this.updateSubscription(newSub, linkPlan);
    }
    if (subResponse) {
      const orgID = this.dashboardOrgID$.value;
      await setDoc(doc(this.driveCol, driveID), { orgID, claimed: true, linkStatus: 'COMPLETE' }, { merge: true });
      await setDoc(doc(this.orgCol, orgID), { driveID }, { merge: true });
      await this.analyticsService.track('descript drive claim successful', { driveInfo: this.linkDrive$.value });
      // Send Braze Completed Event to Descript Endpoint. descript_squadcast_connection_completed
      await this.sendBrazeEvent(driveID, orgID);
      await this.syncSubscription(driveID, orgID, true);
    }
    // this.claiming = false; // Only show modal once?
    this.router.navigate(['/dashboard']);
    this.loading$.next(null);
    await this.amplionService.balanceLedger(this.organizationsService.dashboardOrgID$.value);
    this.toastrService.success('Your Descript Drive has been linked to your SquadCast account', 'Account Linked', {
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      toastComponent: GeneralToastComponent,
    });
  }

  async sendBrazeEvent(driveID: string, orgID: string) {
    this.cfs.post('descript-complete', { driveID, orgID });
  }

  /**
   * Checks if a Stripe Customer exists for the specified organization.
   * If no Stripe Customer exists, it creates a new Stripe Customer.
   * If a Stripe Customer exists, it refreshes the Stripe Customer.
   *
   * @param org The organization to check for a Stripe Customer.
   * @returns A promise that resolves when the Stripe Customer is successfully created or refreshed.
   */
  async checkCustomerExists(org: Organization) {
    await this.dashboardOrg$.nextExistingValue((org) => org.orgID && org.orgName);
    await this.walletService.refreshStripeCustomer(this.dashboardOrg$.value?.customerID);

    if (!this.walletService.customer$.value) {
      const resp = await this.amplionService.addCustomer({
        email: org.billingEmail ?? this.userService.activeUser$.value.email,
        metadata: { orgID: org.orgID },
        description: org.orgName,
      });
      const customerID = resp.customer.id;
      this.walletService.setCustomerID(customerID);
      await this.walletService.customerID$.nextExistingValue(customerID);
      await this.analyticsService.track('descript claim customer creation', {
        customerInfo: { customerID, orgID: org.orgID, driveID: this.linkDrive$.value.driveID },
      });
    }
    await this.walletService.refreshStripeCustomer();
    await this.walletService.customer$.nextExistingValue();
  }

  /**
   * Returns a collection of Descript drives belonging to the specified organization.
   *
   * @param orgID The ID of the organization to retrieve drives for.
   * @returns A collection of Descript drives belonging to the specified organization.
   */
  getOrgDrive(orgID: string) {
    const q = query(this.driveCol, where('orgID', '==', orgID));
    return collectionData(q, { idField: 'driveID' });
  }

  /**
   * Retrieves the data for a specific Descript drive.
   *
   * @param driveID The ID of the Descript drive to retrieve.
   * @returns The data for the specified Descript drive.
   */
  getDrive(driveID: string) {
    return docData(doc(this.driveCol, driveID), { idField: 'driveID' });
  }

  /**
   * Returns a collection of Descript plans.
   *
   * @returns A collection of Descript plans.
   */
  getDescriptPlans() {
    return collectionData(query(this.plansCol, where('isDescriptPlan', '==', true)), { idField: 'id' });
  }

  /**
   * Sends Multiple Selected Files to Descript to generate an Import URL
   *
   * @param idToken : ID Token for Backend Verification
   * @param files : DescriptFile Array
   * @returns
   */
  oldEditInDescript(idToken: string, files: DescriptFile[], is_video?: boolean): Promise<DescriptImport> {
    const headers = new HttpHeaders().set('idToken', idToken);
    const options = { headers };
    return lastValueFrom<DescriptImport>(this.http.post(`${this.califone}/v2/descript`, { files, is_video }, options));
  }

  async editInDescript(recordings: Recording[], format?: 'wav' | 'mp3' | 'mp4', audioOnly = false) {
    this.toastrService.info(
      `Starting Export Process to Edit-In-Descript. Please wait while we prepare your files...`,
      `Starting Export Process to Edit-In-Descript.`,
      {
        closeButton: true,
        tapToDismiss: true,
        disableTimeOut: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      }
    );
    if (!format) format = 'mp4';
    const files = await this.verifyAllFiles(recordings, audioOnly, format);
    if (files.discontinuityRecordings.length > 0) {
      await this.analyticsService.track(`detected audio chromium issue in files `, { success: true });
      this.toastrService.warning(
        `One or more of your recordings appears to have an audio timing issue. This is due to a Chromium bug that effects 
          previous Squadcast Recordings. New Recordings are not affected. You don't need to worry, we are re-rendering your file 
          to fix this issue retroactively. Please wait until the re-render is complete then try exporting again. 
          Found ${files.discontinuityRecordings.length} files effected.`,
        `One or more of your recordings has Warning.`,
        {
          closeButton: false,
          tapToDismiss: true,
          disableTimeOut: true,
          toastComponent: GeneralToastComponent,
          payload: { recordings: files.discontinuityRecordings },
        }
      );

      files.discontinuityRecordings.forEach(async (rec) => {
        const idToken = await this.idTokenService.getFreshIdToken();
        if (rec.fileName.includes('screen')) {
          await firstValueFrom(this.farnsworthService.processRecording(rec, idToken));
        } else {
          // Need to tell the render to also render Video if the recording was a video recording.
          if (rec.hadVideoPlanAtRecording) {
            rec.renderVideo = true;
          }
          await firstValueFrom(this.bendixService.processRecording({ ...rec, preview: false }, idToken));
        }
      });

      await this.analyticsService.track(`fixed audio chromium issue in files `, { success: true });
    } else {
      let msg;
      if (recordings.some((rec) => rec.preview || rec.videoPercentage < 100 || rec.percentage < 100)) {
        msg = 'Some recordings may need more time to process before they can be edited in Descript.';
      }
      if (!files.descriptFiles.length) {
        this.toastrService.warning(msg, `No valid recordings to Edit in Descript`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: true,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        return;
      }

      try {
        const redirect = await this.califoneService.editInDescript(files.descriptFiles, files.hasVideo);

        window.open(redirect.url, '_blank');
        await this.analyticsService.track('opened descript import tab', { success: true });
        const EditInDescriptToast: ActiveToast<GeneralToastComponent> = this.toastrService.success(
          `Your recordings have been sent to your Descript Drive view. ${msg ? msg : ''}`,
          `Successfully Created New Descript Project!`,
          {
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
            toastComponent: GeneralToastComponent,
          }
        );

        EditInDescriptToast.toastRef.componentInstance.buttons = [
          {
            label: 'Open Descript',
            class: 'descript',
            icon: '/assets/icons/24px/descript.svg',
            handler: async () => {
              window.open(
                environment.production
                  ? 'https://web.descript.com?utm_source=squadcast'
                  : 'https://staging-web.descript.com?utm_source=squadcast',
                '_blank'
              );
              await this.analyticsService.track('clicked Open Descript button from Edit In Descript toast');
              EditInDescriptToast.toastRef.close();
            },
          },
        ];
      } catch (e) {
        if (e.error.invalidURIs) {
          const invalidFiles = e.error.invalidURIs
            .map((uri: string) => {
              const fileName = uri.split('/').pop();
              const [name, take] = fileName.split('_');
              return `${name} (Take ${take})`;
            })
            .join(', ');
          this.toastrService.error(
            `There was an error processing some or all of the selected files. \n
           You can also attempt the Edit in Descript process without selecting the following files and then manually upload them into the created project. \n
           Invalid Files: ${invalidFiles}`,
            `Failed to Create New Descript Project!`,
            {
              closeButton: true,
              tapToDismiss: false,
              disableTimeOut: true,
              toastComponent: GeneralToastComponent,
            }
          );
        } else {
          this.toastrService.error(
            `There was an error processing your recordings. Please try again or manually upload your files to Descript.`,
            `Failed to Create New Descript Project!`,
            {
              closeButton: true,
              tapToDismiss: false,
              disableTimeOut: true,
              toastComponent: GeneralToastComponent,
            }
          );
        }
        await this.analyticsService.track('opened descript import tab', { success: false });
      }

    }
  }

  /**
   * Creates a new subscription for a customer and sets the specified plan as the primary plan.
   *
   * @param subscription The subscription details to create.
   * @param plan The plan to set as the primary plan.
   * @returns The new primary subscription.
   */
  async newSubscription(subscription: StripeSubscriptionDTO, plan: Plan) {
    const newPrimary = await this.amplionService.subscribeCustomer(subscription);

    await this.walletService.setPlan(plan.id);
    await this.amplionService.balanceLedger(this.dashboardOrgID$.value);
    await this.analyticsService.track('descript claim new subscription', {
      subscriptionInfo: { ...subscription, driveID: this.linkInfo$.value.driveID },
    });

    return newPrimary;
  }

  /**
   * Updates the subscription for a customer and sets the specified plan as the primary plan.
   * Will cancel any existing shadow plan subscription.
   *
   * @param subscription The subscription details to update.
   * @param plan The plan to set as the primary plan.
   * @returns The new primary subscription.
   */
  async updateSubscription(subscription: StripeSubscriptionDTO, plan: Plan) {
    if (this.walletService.shadowSub$.value) {
      await this.amplionService.cancelSubscription(
        this.walletService.customerID$.value,
        this.walletService.shadowSub$.value.id
      );
      await this.analyticsService.track('descript claim cancel shadow', {
        subscriptionInfo: { ...subscription, driveID: this.linkInfo$.value.driveID },
      });
    }
    const newPrimary = await this.amplionService.updateSubscription(
      subscription,
      this.walletService.primarySub$.value.id
    );

    if (newPrimary) {
      await this.walletService.setPlan(plan.id);
      await this.analyticsService.track('descript claim update subscription', {
        subscriptionInfo: { ...subscription, driveID: this.linkInfo$.value.driveID },
      });
      return newPrimary;
    }
  }

  async syncSubscription(driveID: string, orgID: string, manualSync = false) {
    this.cfs.post('descript-sync', { driveID, orgID, manualSync });
  }

  /**
   * Displays show select modal if there are multiple shows available
   * Creates a new session with the selected show
   * Navigates to the new session
   *
   * @returns void
   * @throws Throws an error if there are no available shows.
   */
  async startSessionNow(compositionName?: string) {
    const memberShows = await firstValueFrom(this.showsService.getMemberShows(this.userService.activeUser$.value.uid));
    if (!memberShows.length) {
      this.toastrService.warning(
        `Could not create the instant session, you are not a member of any shows on this organization. Please ask the organization admin to add you to a show.`,
        `Not a member of any shows`,
        {
          closeButton: true,
          tapToDismiss: false,
          disableTimeOut: true,
          toastComponent: GeneralToastComponent,
        }
      );
      this.router.navigate(['/dashboard']);
      this.loading$.next(null);
      return;
    }
    const showID = memberShows[0].showID;
    const showTitle = memberShows[0].showName;

    const sessionID = this.sessionsService.newSessionID();
    const sessionTitle = compositionName ?? 'Descript Instant Session';
    const date = dayjs().format('YYYY-MM-DD');
    const nextDate = new Date();
    const startTime = nextDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    nextDate.setHours(nextDate.getHours() + 1);
    const endTime = nextDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const dateTime = dayjs(date + ' ' + startTime, 'YYYY-M-DD h:mm A');
    const unixDate = dateTime.unix();
    const orgID = this.dashboardOrgID$.value;

    const session: SessionDTO = {
      sessionTitle,
      showID,
      showTitle,
      orgID,
      startTimestamp: unixDate,
      startTime,
      endTime,
      descriptInstant: true,
      videoEnabled: this.walletService.dashboardPlan$.value?.videoRecording,
      take: 0,
    };

    const event: WebhookEvent = {
      name: WebhookEventNames.SESSION_CREATED,
      userName: this.activeUser$.value.displayName || this.activeUser$.value.email || 'Someone',
      orgID,
      sessionID,
      sessionTitle: session.sessionTitle,
      showID: session.showID,
      showName: session.showTitle,
      startTimestamp: unixDate,
      startTime: session.startTime,
      endTime: session.endTime,
      date: dateTime.toDate(),
    };
    await this.califoneService.emitWebhookEvent(event, orgID);

    await this.sessionsService.saveSessionNew(session, sessionID);
    this.analyticsService.track('saved recording session', {
      sessionTitle: session.sessionTitle,
      showID: session.showID,
      showTitle: session.showTitle,
      startTime: session.startTime,
      videoEnabled: session.videoEnabled,
      date: session.date,
    });
    await this.analyticsService.track('descript instant session created', { sessionInfo: { sessionID, sessionTitle } });
    await this.router.navigate([`studio/${session.showID}/session/${sessionID}`], { replaceUrl: true });
    this.loading$.next(null);
  }

  async checkAllRecordingsOffsets(recordings: Recording[], format: 'wav' | 'mp3' | 'mp4') {
    const filesPromise: Promise<DescriptFile>[] = recordings
      .filter((recording) => recording[format])
      .map(async (recording) => {
        const offset = await this.recordingsService.getRecordingOffsetFromTake(recording);
        return {
          name: `${recording.fileName}.${format}`,
          uri: recording[format],
          start_offset: { seconds: offset },
        } as DescriptFile;
      });

    return filesPromise;
  }

  /**
   * This function will check recording selected to be sent out.
   * @param recordings
   */
  async checkDiscontinuities(rec: Recording) {
    const checkPayload = {
      fileName: rec.fileName,
      recordingID: rec.recordingID,
      sessionID: rec.sessionID,
      showID: rec.showID,
    };
    const hasDiscontinuity: any = await firstValueFrom(await this.farnsworthService.checkDiscontinuity(checkPayload));
    return hasDiscontinuity;
  }

  async verifyAllFiles(recordings: Recording[], audioOnly: boolean, format: 'wav' | 'mp3' | 'mp4') {
    const files: { descriptFiles: DescriptFile[]; discontinuityRecordings: Recording[]; hasVideo: boolean } = {
      descriptFiles: [],
      discontinuityRecordings: [],
      hasVideo: false,
    };
    try {
      const recordingsInfos = recordings.map((rec) => {
        if (rec.enhanced || rec.fileName.includes('enhanced_')) {
          throw new Error('Enhanced recordings cannot be sent to Descript');
        }
        const recInfo = { recordingID: rec.recordingID, sessionID: rec.sessionID };
        return recInfo;
      });

      const discontinuityRecordings = (await firstValueFrom(
        await this.farnsworthService.checkDiscontinuity(recordingsInfos)
      )) as { recordingID: string; result: boolean }[];

      discontinuityRecordings.forEach((rec) => {
        const recording = recordings[rec.recordingID];
        files.discontinuityRecordings.push(recording);
      });

      await Promise.all(
        recordings.map(async (rec) => {
          if (rec.preview || (!rec.wav && !rec.mp4)) return;
          if (rec.enhanced || rec.fileName.includes('enhanced_')) {
            throw new Error('Enhanced recordings cannot be sent to Descript');
          }
          // if (rec.preview || (!rec.wav && !rec.mp4 && !rec.rawURL && !rec.screenRecording)) return;
          const ext = audioOnly ? 'wav' : rec.rawURL ? rec.rawBktPath.split('.').pop() : 'mp4';
          if (ext !== 'wav') files.hasVideo = true;
          const uri = audioOnly ? rec.wav : rec.mp4 ? rec.mp4 : rec.wav;
          // const uri = audioOnly ? rec.wav : rec.mp4 ? rec.mp4 : rec.rawURL ? rec.rawURL : rec.screenRecording ? rec.screenRecording : rec.wav;
          const offset = await this.recordingsService.getRecordingOffsetFromTake(rec);
          files.descriptFiles.push({
            name: `${rec.fileName}.${format}`,
            uri: rec[format],
            start_offset: { seconds: offset },
          } as DescriptFile);
        })
      );
    } catch (error: any) {
      if (error.message === 'Enhanced recordings cannot be sent to Descript') {
        this.toastrService.error(
          `Dolby Enhanced files are not allowed to be sent via Edit in Descript. These are post-processed which causes unintended audio issues. Please use the original recording.`,
          `Enhanced files cannot be sent to Descript.`,
          {
            closeButton: true,
            tapToDismiss: true,
            disableTimeOut: false,
            timeOut: 5 * 2000,
            toastComponent: GeneralToastComponent,
          }
        );
      } else {
        this.toastrService.error(
          `An error occurred while preparing your recordings for Descript. Please try again later.`,
          `Error preparing recordings for Descript`,
          {
            closeButton: true,
            tapToDismiss: true,
            disableTimeOut: false,
            timeOut: 5 * 2000,
            toastComponent: GeneralToastComponent,
          }
        );
      }
    }
    return files;
  }
}
