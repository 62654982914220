import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';

import { Locations, Prompt, PromptTypes } from '@sc/types';
import { DolbyService } from '../dolby/dolby.service';
import { EquipmentService } from '../equipment/equipment.service';
import { MuteService } from '../mute/mute.service';
import { NameService } from '../name/name.service';
import { SessionsService } from '../sessions/sessions.service';
import { StudioSidebarService } from '../studio-sidebar/studio-sidebar.service';
import { UserService } from '../user/user.service';
import { VideoOffService } from '../video-off/video-off.service';

/**
 * Prompts feature actions that the prompted participant can choose to take and, depending on
 * the type of prompt, needs to access the functions to run. A prompt in Firestore is everything
 * except for the buttons (labels & handlers) which can be added to the prompt by calling
 * getPromptActions(). You can think of this service as pre-defined functions to run when one
 * participant prompts another as a safe means of remote code execution.
 */

@Injectable({
  providedIn: 'root',
})
export class PromptsService {
  activeUser$ = this.userService.activeUser$;
  studioSession$ = this.sessionsService.studioSession$;
  actions: {
    [type in PromptTypes]: Array<{
      label: string;
      handler: (prompt: Prompt) => Promise<void>;
    }>;
  } = {
    requestBackstageAccess: [
      { label: 'Decline', handler: this.requestBackstageAccessDeclineHandler.bind(this) },
      { label: 'Accept', handler: this.requestBackstageAccessAcceptHandler.bind(this) },
    ],
    moveParticipantOnStage: [
      { label: 'Decline', handler: this.moveParticipantOnStageDeclineHandler.bind(this) },
      { label: 'Accept', handler: this.moveParticipantOnStageAcceptHandler.bind(this) },
    ],
    moveParticipantBackstage: [{ label: 'Silent', handler: this.moveParticipantBackstageHandler.bind(this) }],
    unmuteMicrophone: [
      { label: 'Decline', handler: this.unmuteMicrophoneDeclineHandler.bind(this) },
      { label: 'Accept', handler: this.unmuteMicrophoneAcceptHandler.bind(this) },
    ],
    enableCamera: [
      { label: 'Decline', handler: this.enableCameraDeclineHandler.bind(this) },
      { label: 'Accept', handler: this.enableCameraAcceptHandler.bind(this) },
    ],
    changeEquipment: [
      { label: 'Decline', handler: this.changeEquipmentDeclineHandler.bind(this) },
      { label: 'Accept', handler: this.changeEquipmentAcceptHandler.bind(this) },
    ],
    atMention: [{ label: 'Reply', handler: this.atMentionReplyHandler.bind(this) }],
    unreadChatGroup: [{ label: 'Close', handler: async () => {} }],
    notifyCreator: [{ label: 'Close', handler: this.notifyCreatorCloseHandler.bind(this) }],
  };

  activePromptIDs = new Set<string>();

  constructor(
    private router: Router,
    private equipmentService: EquipmentService,
    private modalController: ModalController,
    private userService: UserService,
    private sessionsService: SessionsService,
    private nameService: NameService,
    private dolbyService: DolbyService,
    private studioSidebarService: StudioSidebarService,
    private muteService: MuteService,
    private videoOffService: VideoOffService
  ) {}

  /**
   * Gets the actions for a prompt type
   *
   * @param type - PromptTypes
   * @returns prompt actions
   */
  getPromptActions(type: PromptTypes) {
    return this.actions[type];
  }

  /**
   * Deletes the prompt from Firestore to avoid being annoying
   *
   * @param prompt - Prompt
   * @returns - Promise<void>
   */
  async cleanUp(prompt: Prompt) {
    if (!prompt) return;

    if (prompt.presentAs === 'modal') {
      await this.modalController.dismiss();
    }

    await this.sessionsService.deletePrompt(prompt);
    this.activePromptIDs.delete(prompt.promptID);
  }

  /**
   * Declines request for Backstage access by setting the participant's location to not allowed
   * and notify's the creator of the outcome
   *
   * @param prompt - Prompt
   */
  async requestBackstageAccessDeclineHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.setParticipantLocation(
      Locations.NOTALLOWED,
      prompt.creator,
      this.studioSession$.value.sessionID
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your invitation was declined`,
      message: `${name} declined your invitation to go Backstage.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  /**
   * Accepts request for Backstage access by setting the participant's location to backstage
   * and notify's the creator of the outcome
   *
   * @param prompt - Prompt
   */
  async requestBackstageAccessAcceptHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.setParticipantLocation(
      Locations.BACKSTAGE,
      prompt.creator,
      this.studioSession$.value.sessionID
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your invitation was accepted`,
      message: `${name} accepted your invitation to go Backstage.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  /**
   * Declines request for going on The Stage and notify's the creator of the outcome
   *
   * @param prompt - Prompt
   */
  async moveParticipantOnStageDeclineHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your invitation was declined`,
      message: `${name} declined your invitation to go on The Stage.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  /**
   * Accepts request for going on The Stage and notify's the creator of the outcome
   *
   * @param prompt - Prompt
   */
  async moveParticipantOnStageAcceptHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your invitation was accepted`,
      message: `${name} accepted your invitation to go on The Stage.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
    this.dolbyService.leaveConversation(false); // no daily equivalent needed
    window.location.href = `/studio/${this.studioSession$.value.showID}/session/${this.studioSession$.value.sessionID}/guest/anon`;
  }

  /**
   * Moves the participant backstage
   *
   * @param prompt - Prompt
   */
  async moveParticipantBackstageHandler(prompt: Prompt) {
    await this.cleanUp(prompt);
    this.dolbyService.moveToBackstage(); // no daily equivalent needed
  }

  async unmuteMicrophoneDeclineHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your prompt was declined`,
      message: `${name} declined your prompt to unmute their Microphone.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  async unmuteMicrophoneAcceptHandler(prompt: Prompt) {
    this.muteService.setMute(this.studioSession$.value.sessionID, this.activeUser$.value.uid, false);
    await this.cleanUp(prompt);
  }

  async enableCameraDeclineHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your prompt was declined`,
      message: `${name} declined your prompt to enable their Camera.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  async enableCameraAcceptHandler(prompt: Prompt) {
    this.videoOffService.setVideoOff(this.studioSession$.value.sessionID, this.activeUser$.value.uid, false);
    await this.cleanUp(prompt);
  }

  async changeEquipmentDeclineHandler(prompt: Prompt) {
    const name = await firstValueFrom(
      this.nameService.getName(this.studioSession$.value.sessionID, this.activeUser$.value.uid)
    );
    await this.sessionsService.createSessionPrompt({
      title: `Your prompt was declined`,
      message: `${name} declined your prompt to change their equipment.`,
      creator: this.activeUser$.value.uid,
      presentAs: `toast`,
      type: PromptTypes.NOTIFYCREATOR,
      targetParticipants: [prompt.creator],
    });
    await this.cleanUp(prompt);
  }

  async changeEquipmentAcceptHandler(prompt: Prompt) {
    const equipment = this.equipmentService.systemDevices$.value.find(
      (device) => device.deviceId === prompt.data.deviceId
    );
    switch (prompt.data.kind) {
      case 'audioinput':
        this.equipmentService.selectedMicrophone$.next(equipment);
        break;
      case 'videoinput':
        this.equipmentService.selectedCamera$.next(equipment);
        break;
      case 'audiooutput':
        this.equipmentService.selectedHeadphones$.next(equipment);
        break;
    }
    await this.cleanUp(prompt);
  }

  /**
   * Opens the chat group so that the participant can easily reply
   *
   * @param prompt - Prompt
   */
  async atMentionReplyHandler(prompt: Prompt) {
    this.studioSidebarService.openChatGroup(prompt.data.chatGroupID);
    await this.cleanUp(prompt);
  }

  /**
   * Closes the notification prompt
   *
   * @param prompt - Prompt
   */
  async notifyCreatorCloseHandler(prompt: Prompt) {
    await this.cleanUp(prompt);
  }
}
