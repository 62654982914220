import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RecordingService } from '../services/recording/recording.service';
import { SessionsService } from '../services/sessions/sessions.service';
import { DolbyService } from '../services/dolby/dolby.service';
import { DailyService } from '../services/daily/daily.service';

export const canDeactivatePageGuard = (): Observable<boolean> | boolean => {
  const recordingService = inject(RecordingService);
  const sessionsService = inject(SessionsService);
  const dolbyService = inject(DolbyService);
  const dailyService = inject(DailyService);

  const recording$ = recordingService.recording$;
  const dolbyConversation$ = dolbyService.dolbyConversation$;
  const dailyCall$ = dailyService.dailyCall$;
  const session$ = sessionsService.studioSession$;
  const sessionID = session$.value?.sessionID;
  const greenRoomOpen = false;

  if (recording$?.value) {
    recordingService.stopRecording(sessionID);
  }

  if (dolbyConversation$.value) {
    if (!dolbyService.leaving$?.value) {
      dolbyService.leaveConversation(!greenRoomOpen);
    } else {
      dolbyService.leaving$.next(false);
    }
  }

  if (dailyCall$.value) {
    if (!dailyService.leaving$?.value) {
      dailyService.leaveCall(!greenRoomOpen);
    } else {
      dailyService.leaving$.next(false);
    }
  }

  if (session$.value) {
    sessionsService.setStudioSession(null);
  }

  return true;
};
