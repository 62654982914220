import { Injectable } from '@angular/core';
import { Firestore, collection, doc, docData, setDoc } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { stripHTML } from '../../util/stripHTML';
import { ParticipantFsDolby } from '@sc/types';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class NameService {
  sessionsCol = collection(this.firestore, 'sessions');
  // https://www.cs.cmu.edu/~biglou/resources/bad-words.txt
  disallowList: Array<string> = [
    'anal',
    'ana1',
    'anus',
    'anu5',
    'arse',
    'ar5e',
    'babe',
    'barf',
    'bast',
    'blow',
    'bl0w',
    'b10w',
    'b1ow',
    'bomb',
    'b0mb',
    'bong',
    'b0ng',
    'boob',
    'b00b',
    '8oob',
    'b008',
    'butt',
    'clit',
    'c1it',
    'cock',
    'c0ck',
    'c00k',
    'coon',
    'c00n',
    'crap',
    'cumm',
    'cunt',
    'damn',
    'dead',
    'd3ad',
    'dick',
    'd1ck',
    'died',
    'd1ed',
    'dies',
    'd1es',
    'dike',
    'd1ke',
    'dick',
    'd1ck',
    'dink',
    'd1nk',
    'dong',
    'd0ng',
    'dumb',
    'dyke',
    'fart',
    'fear',
    'fuck',
    'fuks',
    'gook',
    'g00k',
    'hell',
    'he11',
    'hoes',
    'h0es',
    'hobo',
    'h0b0',
    'hole',
    'h0le',
    'homo',
    'h0m0',
    'hore',
    'h0re',
    'jigg',
    'j1gg',
    'jism',
    'j1sm',
    'jizm',
    'j1zm',
    'jizz',
    'j1zz',
    'jugs',
    'kike',
    'k1ke',
    'kill',
    'ki11',
    'kink',
    'k1nk',
    'kock',
    'k0ck',
    'koon',
    'k00n',
    'krap',
    'kums',
    'kunt',
    'kyke',
    'laid',
    'lezz',
    'meth',
    'milf',
    'mi1f',
    'mofo',
    'm0f0',
    'muff',
    'nazi',
    'naz1',
    'nigg',
    'n1gg',
    'nigr',
    'n1gr',
    'nude',
    'nuke',
    'oral',
    '0ral',
    'orgy',
    '0rgy',
    'paki',
    'pak1',
    'perv',
    'phuk',
    'phuq',
    'pi55',
    'pimp',
    'p1mp',
    'piss',
    'p1ss',
    'pi55',
    'p155',
    'poon',
    'p00n',
    'p0on',
    'po0n',
    'poop',
    'p00p',
    'po0p',
    'p0op',
    'porn',
    'p0rn',
    'pric',
    'pr1c',
    'pube',
    'puke',
    'puss',
    'pu55',
    'pus5',
    'pu5s',
    'pusy',
    'pu5y',
    'rape',
    'rump',
    'scum',
    '5cum',
    'sexy',
    '5exy',
    'shat',
    '5hat',
    'shit',
    '5hit',
    '5h1t',
    'sick',
    's1ck',
    '5ick',
    '51ck',
    'skum',
    '5kum',
    'slav',
    '5lav',
    '51av',
    'slut',
    '5lut',
    's1ut',
    '51ut',
    'smut',
    '5mut',
    'snot',
    '5not',
    '5n0t',
    'spic',
    '5pic',
    '5p1c',
    '5pic',
    'spik',
    '5p1k',
    '5pik',
    'suck',
    '5uck',
    'tang',
    'tard',
    'tits',
    't1ts',
    't1t5',
    'tit5',
    'turd',
    'twat',
    'wank',
    'wuss',
    'wu55',
  ];

  constructor(
    private firestore: Firestore,
    private userService: UserService,
    private sessionsService: SessionsService
  ) {}

  setName(sessionID: string, castMemberID: string, name: string) {
    name = stripHTML(name);
    if (this.userService.activeUser$.value.guest) this.userService.setAnonData({ displayName: name });
    this.sessionsService.setParticipantSearchInfo(castMemberID, sessionID, { name });
    const participantCol = collection(this.sessionsCol, sessionID, 'participants');
    return setDoc(doc(participantCol, castMemberID), { name }, { merge: true });
  }

  async getRandomNameIfEmpty(name = '') {
    if (!name) {
      return await this.getRandomName(4);
    } else return name;
  }

  getName(sessionID: string, castMemberID: string) {
    return docData(doc(this.sessionsCol, sessionID, 'participants', castMemberID)).pipe(
      map((participant: ParticipantFsDolby) => participant?.name || '')
    );
  }

  getRandomName(length: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const random = Math.random().toString(20).substr(2, length);

      this.disallowList.map((disallowItem: string, index: number) => {
        if (random === disallowItem) {
          this.getRandomName(length); // disallowed, try again
        } else if (this.disallowList.length - 1 === index) {
          // done
          resolve(`SquadCaster ${random}`); // allowed SquadCast uiwqu128
        }
      });
    });
  }
}
