import { Component, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectOption } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { firstValueFrom, Subject, Subscription, throttleTime } from 'rxjs';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Timestamp } from '@angular/fire/firestore/firebase';
import { ModalController, PopoverController, SegmentCustomEvent, ToggleCustomEvent } from '@ionic/angular';
import Rollbar from 'rollbar';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

import { ConfTypes, GuestInvite, RoleLimits, Session, Show } from '@sc/types';
import { Timezone } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { WalletService } from '../../services/wallet/wallet.service';
import { WebhookEvent, WebhookEventNames } from '@sc/types';
import { SessionsService } from '../../services/sessions/sessions.service';
import { TeamMember } from '@sc/types';
import { ShowsService } from '../../services/shows/shows.service';
import { SessionDTO } from '@sc/types';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { LinksService } from '../../services/links/links.service';
import { ZapierService } from '../../services/zapier/zapier.service';
import { CalendarLinksService } from '../../services/calendar-links/calendar-links.service';
import { InputDateComponent } from '../../components/input-date/input-date.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';
import { TeamsService } from '../../services/teams/teams.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { TimezoneService } from '../../services/timezone/timezone.service';
import { SettingsService } from '../../services/settings/settings.service';
import { RolesInfoMenuComponent } from '../../menus/roles-info-menu/roles-info-menu.component';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { Roles } from '@sc/types';
import { ActionConfirmPage } from '../../modals/action-confirm/action-confirm.page';
import { defaultPopoverOptions } from '../../services/theme/theme.service';
import { stripHTML } from '../../util/stripHTML';
import { RolesService } from '../../services/roles/roles.service';
import { SCSubject } from '../../util/sc-subject.class';
import { Location } from '@angular/common';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

@Component({
  selector: 'sc-add-session',
  templateUrl: 'add-session.page.html',
  styleUrls: ['add-session.page.scss'],
})
export class AddSessionPage implements OnInit, OnDestroy {
  @ViewChild('viewerSelect') viewerSelect: NgSelectComponent;
  @ViewChild('stageSelect') stageSelect: NgSelectComponent;
  @ViewChild('inputDate') inputDate: InputDateComponent;
  @ViewChild('inputStartTime') inputStartTime: InputDateComponent;
  @ViewChild('inputEndTime') inputEndTime: InputDateComponent;

  user$ = this.userService.activeUser$;
  plan$ = this.walletService.dashboardPlan$;
  appSettings$ = this.settingsService.userAppSettings$;
  availableShows$ = this.showsService.availableShows$;
  role$ = this.rolesService.role$;
  saveSession$ = new Subject<boolean>();
  editSessionReady$ = new SCSubject<boolean>(false);
  availableMembersReady$ = new SCSubject<boolean>(false);
  roleLimits = RoleLimits;
  selectedShow: Show;
  editingSession: Session;
  sessionLoaded = false;
  sendingEmails = false;
  savingSession = false;

  timezones: Array<Timezone>;
  timezone: Timezone;
  videoEnabled = this.plan$.value?.videoRecording ? true : false; // True = AV | False = A Only

  stageLinkButtonText = 'Copy Stage Link';
  backstageLinkButtonText = 'Copy Backstage Link';

  editing = false;
  sendStageInvites = true;
  sendBackstageInvites = true;
  backstageVisible = false;
  selectTypingValue = '';

  availableMembers: TeamMember[] = [];

  onStageInviteLink: string;
  backstageInviteLink: string;
  confType: ConfTypes = ConfTypes.DAILY;
  sessionTitle: string;
  sessionDuration = 60;
  sessionDurationString: string;
  startTimestamp: number;
  startTime: string;
  endTime: string;
  date: string;
  sessionID: string;
  maxQuality = false;

  viewerMembers: TeamMember[] = [];
  stageMembers: TeamMember[] = [];

  subs: Array<Subscription> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private calendarLinksService: CalendarLinksService,
    private califoneService: CalifoneService,
    private linksService: LinksService,
    private modalController: ModalController,
    private organizationsService: OrganizationsService,
    private popoverController: PopoverController,
    private rolesService: RolesService,
    private location: Location,
    private router: Router,
    private sessionsService: SessionsService,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private teamsService: TeamsService,
    private timezoneService: TimezoneService,
    private toastrService: ToastrService,
    private userService: UserService,
    private walletService: WalletService,
    private zapierService: ZapierService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  async ngOnInit() {
    this.setupDashboardShow();
    this.setupTimezone();
    this.setupAvailableMembers();
    this.sessionID = this.sessionsService.newSessionID();
    this.setupEditSession();
    this.setupSaveSession();
  }

  async sendEmails() {
    this.sendingEmails = true;
    try {
      const session = await this.saveSession();
      await this.sendOnStageInviteEmails();
      await this.sendBackstageInviteEmails();
      this.router.navigate(['/dashboard'], { replaceUrl: true });
      this.toastrService.success('Your invites have been sent!', `Email invites sent for ${this.sessionTitle}!`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      const webhookEvent: WebhookEvent = {
        name: this.editing ? WebhookEventNames.SESSION_UPDATED : WebhookEventNames.SESSION_CREATED,
        userName: this.user$.value.displayName || this.user$.value.email || 'Someone',
        orgID: this.organizationsService.dashboardOrgID$.value,
        sessionID: this.sessionID,
        sessionTitle: this.sessionTitle || 'Recording Session',
        showID: this.selectedShow.showID,
        showName: this.selectedShow.showName || 'My Show',
        startTimestamp: session.startTimestamp,
        startTime: session.startTime,
        endTime: session.endTime,
        date: session.date,
        timezone: this.timezone,
        invites: session.invites,
        sessionParticipants: session.participants.map((p) => p.name),
      };
      await this.califoneService.emitWebhookEvent(webhookEvent, this.organizationsService.dashboardOrgID$.value);

      this.analyticsService.track('sent recording session invites', {
        sessionTitle: session.sessionTitle,
        showID: session.showID,
        showTitle: session.showTitle,
        startTime: session.startTime,
        videoEnabled: session.videoEnabled,
        date: session.date,
      });
    } finally {
      this.sendingEmails = false;
    }
  }

  async changeShow() {
    this.showsService.setDashboardShow(this.selectedShow.showID);
    this.resetForm();
    this.createInviteLinks();
    this.saveSession$.next(true);
  }

  async setupTimezone() {
    this.timezones = await this.timezoneService.getTimezones();
    this.timezone = (await this.appSettings$.toPromise())?.timezone;
    if (!this.timezone) {
      const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timezone = this.timezones.find((tz) => tz.tzid === tzid);
    }
    if (!this.timezone) this.timezone = this.timezones[15]; // Default to Eastern
  }

  setupDashboardShow() {
    const sub = this.showsService.dashboardShow$.subscribe(async (show) => {
      if (!show && !this.editing) {
        this.selectFirstAvailableShow();
        return;
      }
      await firstValueFrom(this.activatedRoute.params);
      if (!this.selectedShow || (this.selectedShow.showID !== show?.showID && !this.editing)) {
        this.resetForm();
        this.selectedShow = this.availableShows$.value.find((s) => s.showID === show.showID);
        this.addShowManagerIfNotPresent();
      }
    });
    this.subs.push(sub);
  }

  onTitleBlur() {
    if (this.editing && this.sessionTitle == this.editingSession.sessionTitle) return;
    this.saveSession$.next(true);
  }

  async addShowManagerIfNotPresent() {
    // Wait for UI to catch up!
    await this.teamsService.dashboardShowMembers$.nextExistingValue((val) => val !== null);
    await new Promise((resolve) => setTimeout(resolve, 0));

    const showManagerPresent =
      this.stageMembers.some((member) => member.role >= Roles.SHOW_MANAGER) ||
      this.viewerMembers.some((member) => member.role >= Roles.SHOW_MANAGER);
    const isSelfAvailable = this.availableMembers.find(
      (member) => member.uid === this.user$.value.uid && member.role >= Roles.SHOW_MANAGER
    );
    const showManager = isSelfAvailable || this.availableMembers.find((member) => member.role >= Roles.SHOW_MANAGER);

    if (!showManagerPresent && showManager) {
      this.selectMember(this.stageSelect, showManager);
    }
  }

  async selectFirstAvailableShow() {
    const shows = await this.showsService.availableShows$.toPromise();
    this.showsService.setDashboardShow(shows[0].showID);
    this.selectedShow = shows[0];
  }

  async setupEditSession() {
    const sub = this.activatedRoute.params.subscribe(async (params) => {
      await this.availableShows$.nextExistingValue((val) => val.length > 0);
      if (params.id) {
        this.editing = true;
        this.sendStageInvites = false;
        this.sendBackstageInvites = false;
        this.sessionID = params.id;
        const session = await firstValueFrom(this.sessionsService.getSessionByID(this.sessionID));
        this.resetForm();
        if (session.videoEnabled === undefined) {
          session.videoEnabled = this.plan$.value.videoRecording;
        }

        if (session.maxQuality === undefined) {
          const showSettings = await firstValueFrom(this.settingsService.getShowSettings(session.showID));
          if (showSettings.maxQuality) session.maxQuality = true;
        }

        this.showsService.setDashboardShow(session.showID);
        this.selectedShow = this.availableShows$.value.filter((show) => show.showID === session.showID)[0];
        this.sessionTitle = session.sessionTitle;
        this.videoEnabled = session.videoEnabled;
        this.maxQuality = session.maxQuality;
        this.editingSession = session;
        this.backstageVisible = session.invites.viewer.length > 0;

        // Wait for UI to catch up!
        await new Promise((resolve) => setTimeout(resolve, 0));

        let start = dayjs(session.startTime);
        if (!start.isValid()) start = dayjs(session.startTime, 'h:mm A');
        let end = dayjs(session.endTime);
        if (!end.isValid()) end = dayjs(session.endTime, 'h:mm A');

        this.setSessionDuration(end.diff(start, 'minute'));
        this.inputStartTime.selectedDate(session.startTime);
        this.inputDate.selectedDate((session.date as Timestamp).toDate().toISOString());

        this.availableMembers = await this.teamsService.dashboardShowMembers$.nextExistingValue((val) => val !== null);
        await new Promise((resolve) => setTimeout(resolve, 0));

        for (const email of session.invites.stage) {
          this.selectMember(this.stageSelect, { email });
        }

        for (const email of session.invites.viewer) {
          this.selectMember(this.viewerSelect, { email });
        }
        this.createInviteLinks();
        await new Promise((resolve) => setTimeout(resolve, 0));
        this.editSessionReady$.next(true);
      }
    });

    this.subs.push(sub);
  }

  async setupSaveSession() {
    await firstValueFrom(this.activatedRoute.params);
    if (this.editing) {
      await this.editSessionReady$.nextExistingValue((val) => val === true);
      await this.availableMembersReady$.nextExistingValue((val) => val === true);
    }
    this.saveSession$.next(false);
    this.sessionLoaded = true;
    const sub = this.saveSession$.pipe(throttleTime(1000, null, { trailing: true })).subscribe(async (save) => {
      if (save) {
        const session = await this.saveSession();
        if (!session) return;
        this.analyticsService.track('auto saved recording session', {
          sessionTitle: session.sessionTitle,
          showID: session.showID,
          showTitle: session.showTitle,
          startTime: session.startTime,
          videoEnabled: session.videoEnabled,
          date: session.date,
        });
      }
    });
    this.subs.push(sub);
  }

  resetForm() {
    this.viewerMembers = [];
    this.stageMembers = [];
    this.stageSelect?.selectedItems.forEach((item) => {
      this.stageSelect.unselect(item);
    });
    this.viewerSelect?.selectedItems.forEach((item) => {
      this.viewerSelect.unselect(item);
    });
  }

  setupAvailableMembers() {
    const sub = this.teamsService.dashboardShowMembers$.subscribe(async (members) => {
      if (members === null) return;
      this.availableMembers = members.filter((member) => {
        return (
          !this.viewerMembers.find((m) => m.uid === member.uid) && !this.stageMembers.find((m) => m.uid === member.uid)
        );
      });

      this.viewerMembers = this.viewerMembers.map((member) => {
        const fullMember = members.find((m) => m.uid === member.uid || m.email === member.email);
        if (fullMember) {
          this.removeFromAvailableMembers(fullMember);
          return fullMember;
        }
        return member;
      });
      this.stageMembers = this.stageMembers.map((member) => {
        const fullMember = members.find((m) => m.uid === member.uid || m.email === member.email);
        if (fullMember) {
          this.removeFromAvailableMembers(fullMember);
          return fullMember;
        }
        return member;
      });
      this.availableMembersReady$.next(true);
    });
    this.subs.push(sub);
  }

  addMember(member: TeamMember, location: 'stage' | 'viewer') {
    if (location === 'stage') {
      if (this.stageMembers.length >= this.plan$.value.inStudio) {
        const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
          `You have reached the participant limit on the stage.`,
          `Stage is full`,
          {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 10 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );
        toast.toastRef.componentInstance.buttons = [
          {
            label: 'Upgrade Subscription',
            handler: () => {
              this.router.navigate(['/account/subscription'], { replaceUrl: true });
            },
          },
        ];
        return;
      }
      this.stageMembers.push(member);
    }
    if (location === 'viewer') {
      if (this.viewerMembers.length >= this.plan$.value.backstage) {
        const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
          `You have reached the participant limit in the backstage.`,
          `Backstage is full`,
          {
            progressBar: true,
            progressAnimation: 'decreasing',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 10 * 1000,
            toastComponent: GeneralToastComponent,
          }
        );
        toast.toastRef.componentInstance.buttons = [
          {
            label: 'Upgrade Subscription',
            handler: () => {
              this.router.navigate(['/account/subscription'], { replaceUrl: true });
            },
          },
        ];
        return;
      }
      this.viewerMembers.push(member);
    }
    this.selectTypingValue = '';
    this.removeFromAvailableMembers(member);
    this.saveSession$.next(true);
  }

  removeFromAvailableMembers(member: TeamMember): void {
    if (member.uid) {
      this.availableMembers = this.availableMembers.filter((available) => available.uid !== member.uid);
    }
  }

  async moveStage(member: TeamMember) {
    if (this.stageMembers.length >= this.plan$.value.inStudio) {
      const toast: ActiveToast<GeneralToastComponent> = this.toastrService.error(
        `You have reached the participant limit on the stage.`,
        `Stage is full`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
      toast.toastRef.componentInstance.buttons = [
        {
          label: 'Upgrade Subscription',
          handler: () => {
            this.router.navigate(['/account/subscription'], { replaceUrl: true });
          },
        },
      ];
      return;
    }
    this.viewerRemove(member);

    // wait for the member to become available again
    await new Promise((resolve) => setTimeout(resolve, 0));

    this.selectMember(this.stageSelect, member);
  }

  selectMember(select, member) {
    const selectMember = select.itemsList.items.find(
      (m) => (member.uid && member.uid === m.value.uid) || (member.email && member.email === m.value.email)
    );
    if (selectMember) {
      select.select(selectMember);
    } else {
      const validEmail = this.addTag(member.email);
      if (validEmail) select.select({ value: validEmail });
    }
  }

  participantRemove(member: TeamMember): void {
    const item = this.stageSelect.selectedItems.find((opt: NgSelectOption) => {
      return (opt.value.uid && opt.value.uid === member.uid) || (opt.value.email && opt.value.email === member.email);
    });
    this.stageMembers = this.stageMembers.filter((m) => {
      return m.email !== member.email;
    });
    this.stageSelect.unselect(item);
    if (member.uid) this.availableMembers = [...this.availableMembers, member];
    this.saveSession$.next(true);
  }
  viewerRemove(member: TeamMember): void {
    const item = this.viewerSelect.selectedItems.find((opt: NgSelectOption) => {
      return (opt.value.uid && opt.value.uid === member.uid) || (opt.value.email && opt.value.email === member.email);
    });
    this.viewerMembers = this.viewerMembers.filter((m) => {
      return m.email !== member.email;
    });
    this.viewerSelect.unselect(item);
    if (member.uid) this.availableMembers = [...this.availableMembers, member];
    this.saveSession$.next(true);
  }

  dateSet(dateObj: { dateISO: string; displayDate: string }) {
    this.date = dateObj.displayDate;
    this.saveSession$.next(true);
  }

  startTimeSet(dateObj: { dateISO: string; displayDate: string }) {
    this.startTime = dateObj.displayDate;
    if (this.inputEndTime) {
      this.inputEndTime.selectedDate(dayjs(dateObj.dateISO).add(this.sessionDuration, 'minute').toISOString());
    }
    this.saveSession$.next(true);
  }
  endTimeSet(dateObj: { dateISO: string; displayDate: string }) {
    this.endTime = dateObj.displayDate;
    if (this.inputStartTime.dateISO) {
      this.setSessionDuration(dayjs(dateObj.dateISO).diff(dayjs(this.inputStartTime.dateISO), 'minute'));
    }
    this.saveSession$.next(true);
  }

  ngSelectSearch(event) {
    this.selectTypingValue = event.term;
  }

  selectBlur(select) {
    if (this.selectTypingValue) {
      const member = select.itemsList.items.find(
        (m) =>
          m.value?.email?.toLocaleLowerCase().includes(this.selectTypingValue.toLocaleLowerCase()) ||
          m.value?.displayName?.toLowerCase().includes(this.selectTypingValue.toLowerCase())
      );
      if (member) {
        select.select(member);
      } else {
        const validEmail = this.addTag(this.selectTypingValue);
        if (validEmail) select.select({ value: validEmail });
      }
    }
  }

  addTag(tag: string) {
    const isEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (isEmail.test(tag)) return { email: tag.toLowerCase() };
  }

  async createBackstage() {
    this.backstageVisible = true;
    await new Promise((resolve) => setTimeout(resolve, 0));
    this.viewerSelect.focus();
  }

  async saveSession() {
    this.savingSession = true;
    if (!this.selectedShow || !this.sessionTitle) {
      this.savingSession = false;
      return;
    }

    this.sessionTitle = stripHTML(this.sessionTitle);
    if (!this.sessionTitle || !this.sessionTitle.length || this.sessionTitle.length > 100) {
      this.toastrService.error(`Title must be between 1 and 100 characters.`, `Title is Required`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 10 * 1000,
        toastComponent: GeneralToastComponent,
      });
      this.savingSession = false;
      return;
    }
    this.savingSession = true;

    this.createInviteLinks();

    const dateTime = dayjs.tz(`${this.date} ${this.startTime}`, 'l LT', this.timezone.tzid);
    const startTime = dateTime.toISOString();
    const endTime = dateTime.add(this.sessionDuration, 'minute').toISOString();

    const unixDate = dateTime.unix();
    let session: SessionDTO = {
      confType: this.confType,
      sessionTitle: this.sessionTitle,
      showID: this.selectedShow.showID,
      showTitle: this.selectedShow.showName,
      orgID: this.organizationsService.dashboardOrgID$.value,
      startTimestamp: unixDate,
      startTime,
      endTime,
      timezone: this.timezone,
      videoEnabled: this.videoEnabled,
      maxQuality: this.maxQuality,
      date: dateTime.toDate(),
      take: 0,
    };

    session = this.buildSessionParticipants(session);
    try {
      if (this.editing) {
        await this.sessionsService.updateSession(session, this.sessionID);
      } else {
        const sessionID = await this.sessionsService.saveSessionNew(session, this.sessionID);
        this.location.replaceState(`/dashboard/edit-session/${sessionID}`);
      }
      this.editingSession = session;
      this.editing = true;
    } catch (e) {
      this.toastrService.error(
        `There was an error while creating the session. Please double check if it was successfully created.`,
        `Session Creation Warning`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 10 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );

      this.rollbar.error('Failed to create/save session', e);
    } finally {
      this.savingSession = false;
    }
    return session;
  }

  buildSessionParticipants(session: SessionDTO) {
    const stageInvites = [];
    const viewerInvites = [];
    session.participants = [];
    this.stageMembers.forEach((member) => {
      if (member) {
        stageInvites.push(member.email);
        if (member.uid) {
          session.participants.push({
            email: member.email,
            uid: member.uid,
            name: member.displayName,
            role: member.role,
          });
        }
      }
    });
    this.viewerMembers.forEach((member) => {
      if (member) {
        viewerInvites.push(member.email);
        if (member.uid) {
          member.backstage = true;
          session.participants.push({
            email: member.email,
            uid: member.uid,
            name: member.displayName,
            role: member.role,
          });
        }
      }
    });
    session.invites = { stage: stageInvites, viewer: viewerInvites };
    return session;
  }

  async checkForShowManager() {
    const showManagerPresent =
      this.stageMembers.some((member) => member.role >= Roles.SHOW_MANAGER) ||
      this.viewerMembers.some((member) => member.role >= Roles.SHOW_MANAGER);
    if (!showManagerPresent) {
      const continueWithoutShowManger = await new Promise(async (res) => {
        const modal = await this.modalController.create({
          component: ActionConfirmPage,
          componentProps: {
            title: `Continue without a Show Manager?`,
            message: `You are about to create a session without sending any invites to a Show Manager. Only a show manager can start recording in a session.`,
            buttons: [
              {
                label: 'Continue',
                handler: async () => {
                  modal.dismiss();
                  return res(true);
                },
                type: 'warning',
              },
              {
                label: 'Cancel',
                handler: async () => {
                  modal.dismiss();
                  return res(false);
                },
              },
            ],
          },
          showBackdrop: true,
          backdropDismiss: true,
          animated: true,
          cssClass: 'action-confirm-modal',
        });

        await modal.present();
      });

      if (!continueWithoutShowManger) return;
    }
  }

  async formatChanged(event: Event) {
    const ev = event as SegmentCustomEvent;
    const evVideo = ev.detail.value === 'video';
    if (evVideo && !this.plan$.value?.videoRecording) {
      this.router.navigate(['account/subscription'], { replaceUrl: true });
      return;
    }
    if (this.editingSession?.recordingStarted && evVideo !== this.videoEnabled) {
      const modal = await this.modalController.create({
        component: ActionConfirmPage,
        componentProps: {
          title: `May impact Cloud Recordings`,
          message: `Changing this setting may impact any Cloud Recording backups that have not yet been generated. Are you sure?`,
          buttons: [
            {
              label: 'Continue',
              handler: async () => {
                modal.dismiss(true);
              },
              type: 'warning',
            },
            {
              label: 'Cancel',
              handler: async () => {
                modal.dismiss(false);
              },
            },
          ],
        },
        showBackdrop: true,
        backdropDismiss: true,
        animated: true,
        cssClass: 'action-confirm-modal',
      });

      await modal.present();
      const resp = await modal.onDidDismiss();
      if (!resp.data) {
        this.videoEnabled = !this.videoEnabled;
        this.toggleFormatAsync();
        return;
      } else {
        this.videoEnabled = evVideo;
        this.saveSession$.next(true);
      }
    } else {
      this.videoEnabled = evVideo;
      this.saveSession$.next(true);
    }
  }

  async recordingQualityChanged(event: ICustomEvent) {
    this.saveSession$.next(true);
    this.analyticsService.track(`toggled session quality`, {
      sessionID: this.sessionID,
      enabled: event.detail.checked,
    });
  }

  async toggleFormatAsync() {
    setTimeout(() => {
      this.videoEnabled = !this.videoEnabled;
    }, 0);
  }

  async sendOnStageInviteEmails() {
    const protocol = window.location.protocol;
    const domain = window.location.host;

    const onStageInvites = this.stageMembers.map(async (member: TeamMember) => {
      const toEmail = member.email;
      if (!toEmail) return;

      const url = `${protocol}//${domain}/studio/${this.selectedShow.showID}/session/${this.sessionID}/guest/${member.email}`;
      const title = `You are invited to record on ${this.selectedShow.showName}`;
      const description = `🔴 Recorded on SquadCast`;
      const shortLinkResponse: any = await this.linksService.getShortLink({ url, title, description });
      const shortLink = JSON.parse(shortLinkResponse).shortLink;
      const sessionStartDate = dayjs.tz(`${this.date} ${this.startTime}`, 'l LT', this.timezone.tzid);
      const sessionEndDate = sessionStartDate.add(this.sessionDuration, 'minute');

      const date = `${dayjs(this.date).format('ddd, MMM Do')} @ ${this.startTime} ${dayjs(sessionStartDate).format(
        'z'
      )}`;
      let startDateTime;
      let endDateTime;
      if (this.timezone.tzid !== undefined) {
        startDateTime = sessionStartDate.tz(this.timezone.tzid);
        endDateTime = sessionEndDate.tz(this.timezone.tzid);
      } else {
        startDateTime = dayjs(`${this.date} ${this.startTime}`, 'l LT').utc();
        endDateTime = startDateTime.add(this.sessionDuration, 'minute').utc();
      }
      const addToCalendarConfig = {
        title,
        description,
        email: this.user$.value.email,
        start: startDateTime.format('YYYY-MM-DD h:mm A'),
        end: endDateTime.format('YYYY-MM-DD h:mm A'),
        timezone: this.timezone,
        link: shortLink,
      };
      const newInvite: GuestInvite = {
        role: Roles.GUEST,
        sessionID: this.sessionID,
        title,
        fromEmail: this.user$.value.email,
        toEmail: toEmail.toLowerCase(),
        date,
        inviteURL: shortLink,
        showName: this.selectedShow.showName,
        showImg: this.selectedShow.showImg,
        format: this.videoEnabled ? 'Video and Audio' : 'Audio Only',
        google: this.calendarLinksService.getLink('google', addToCalendarConfig),
        apple: this.calendarLinksService.getLink('apple', addToCalendarConfig),
        outlook: this.calendarLinksService.getLink('outlook', addToCalendarConfig),
      };
      const guestInviteID = (await this.zapierService.createGuestInvite(newInvite)).id;
      await this.zapierService.sendOnStageInviteEmail(guestInviteID);
    });

    await Promise.all(onStageInvites);
  }

  async sendBackstageInviteEmails() {
    const protocol = window.location.protocol;
    const domain = window.location.host;

    const backstageInvites = this.viewerMembers.map(async (member: TeamMember) => {
      const toEmail = member.email;
      if (!toEmail) return;

      const url = `${protocol}//${domain}/studio/${this.selectedShow.showID}/session/${this.sessionID}/location/backstage/${member.email}`;
      const title = `You are invited to ${this.selectedShow.showName}'s Backstage`;
      const description = `🔴 Recorded on SquadCast`;
      const shortLinkResponse: any = await this.linksService.getShortLink({ url, title, description });
      const shortLink = JSON.parse(shortLinkResponse).shortLink;
      const sessionStartDate = dayjs.tz(`${this.date} ${this.startTime}`, 'l LT', this.timezone.tzid);
      const sessionEndDate = sessionStartDate.add(this.sessionDuration, 'minute');

      const date = `${dayjs(this.date).format('ddd, MMM Do')} @ ${this.startTime} ${dayjs(sessionStartDate).format(
        'z'
      )}`;
      let startDateTime;
      let endDateTime;

      if (this.timezone.tzid !== undefined) {
        startDateTime = sessionStartDate.tz(this.timezone.tzid);
        endDateTime = sessionEndDate.tz(this.timezone.tzid);
      } else {
        startDateTime = dayjs(`${this.date} ${this.startTime}`, 'l LT').utc();
        endDateTime = sessionStartDate.add(this.sessionDuration, 'minute').utc();
      }
      const addToCalendarConfig = {
        title,
        description,
        email: this.user$.value.email,
        start: startDateTime.format('YYYY-MM-DD h:mm A'),
        end: endDateTime.format('YYYY-MM-DD h:mm A'),
        timezone: this.timezone,
        link: shortLink,
      };

      const newInvite: GuestInvite = {
        role: Roles.VIEWER,
        sessionID: this.sessionID,
        title,
        fromEmail: this.user$.value.email,
        toEmail: toEmail.toLowerCase(),
        date,
        inviteURL: shortLink,
        showName: this.selectedShow.showName,
        showImg: this.selectedShow.showImg,
        google: this.calendarLinksService.getLink('google', addToCalendarConfig),
        apple: this.calendarLinksService.getLink('apple', addToCalendarConfig),
        outlook: this.calendarLinksService.getLink('outlook', addToCalendarConfig),
      };

      const guestInviteID = (await this.zapierService.createGuestInvite(newInvite)).id;
      await this.zapierService.sendBackstageInviteEmail(guestInviteID);
    });

    await Promise.all(backstageInvites);
  }

  async createInviteLinks() {
    if (!this.selectedShow || !this.sessionTitle) return;

    const { protocol, host } = window.location;

    const longLinkOnStage = `${protocol}//${host}/studio/${this.selectedShow.showID}/session/${this.sessionID}/guest/anon`;
    const shortLinkOnStage: any = await this.linksService.getShortLink({
      url: longLinkOnStage,
      title: `On Stage Invitation to "${this.sessionTitle}" on ${this.selectedShow.showName}`,
      description: `You are Invited to a Recording Session, 🔴 Recorded on SquadCast`,
    });
    const parsedLinkOnStage = JSON.parse(shortLinkOnStage);

    const longLinkBackstage = `${protocol}//${host}/studio/${this.selectedShow.showID}/session/${this.sessionID}/location/backstage`;
    const shortLinkBackstage: any = await this.linksService.getShortLink({
      url: longLinkBackstage,
      title: `Backstage Invitation to "${this.sessionTitle}" on ${this.selectedShow.showName}`,
      description: `You are Invited to a Recording Session, 🔴 Recorded on SquadCast`,
    });
    const parsedLinkBackstage = JSON.parse(shortLinkBackstage);

    if (this.onStageInviteLink && this.onStageInviteLink !== parsedLinkOnStage.shortLink) {
      this.toastrService.success(
        `The share links have been updated, previous share links may no longer work correctly.`,
        `Share links updated!`,
        {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        }
      );
    }

    this.onStageInviteLink = parsedLinkOnStage.shortLink;
    this.backstageInviteLink = parsedLinkBackstage.shortLink;
  }

  selectInviteLink(event) {
    if (!this.onStageInviteLink) return;
    const urlEl =
      event.target.localName === 'ion-item' ? event.target.children[1] : event.target.parentElement.children[1];
    window.getSelection().selectAllChildren(urlEl);
  }

  async copyInviteLink(location: 'stage' | 'backstage') {
    const link = location === 'stage' ? this.onStageInviteLink : this.backstageInviteLink;
    try {
      await navigator.clipboard.writeText(link);
      this.toastrService.success(`Invitation Link: ${link}`, `Successfully copied to clipboard`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });

      this.analyticsService.track('copied invite link', {
        location,
      });
    } catch (error) {
      this.toastrService.error(`Invitation Link: ${link}`, `Failed to copy to clipboard`, {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }

    if (location === 'stage') {
      this.stageLinkButtonText = 'Copied Link!';
      setTimeout(() => {
        this.stageLinkButtonText = 'Copy Stage Link';
      }, 4 * 1000);
    }
    if (location === 'backstage') {
      this.backstageLinkButtonText = 'Copied Link!';
      setTimeout(() => {
        this.backstageLinkButtonText = 'Copy Backstage Link';
      }, 4 * 1000);
    }
  }

  async openRolesInfoMenu(event) {
    const menu = await this.popoverController.create({
      ...defaultPopoverOptions,
      component: RolesInfoMenuComponent,
      event,
    });
    await menu.present();
  }

  setSessionDuration(minutes: number) {
    const minInDay = 24 * 60;
    minutes = ((minutes % minInDay) + minInDay) % minInDay;
    this.sessionDuration = minutes;

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    let string = '';
    if (hours > 0) {
      string += `${hours}h`;
    }
    if (hours > 0 && mins > 0) {
      string += ' ';
    }
    if (mins > 0) {
      string += `${mins}m`;
    }
    this.sessionDurationString = string;
  }

  done() {
    this.router.navigate(['dashboard'], { replaceUrl: true });
    this.analyticsService.track('finished editing recording session');
  }

  async ngOnDestroy() {
    if (this.sessionTitle && this.selectedShow && !this.savingSession) {
      await this.saveSession();
      this.toastrService.success(`Session '${this.sessionTitle}' has been saved!`, 'Session saved!', {
        progressBar: true,
        progressAnimation: 'decreasing',
        closeButton: true,
        tapToDismiss: false,
        timeOut: 5 * 1000,
        toastComponent: GeneralToastComponent,
      });
    }
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
