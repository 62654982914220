import { StudioSidebarItem } from './studio-sidebar-options.interface';
import { Timezone } from './timezone.interface';
export interface AppSettings {
  theme?: Themes;
  askFeedback?: boolean;
  muteOnJoin?: boolean;
  seeCommunityEvents?: boolean;
  timezone?: Timezone;
  timePickerMinuteInterval?: number;
  showJoinSessionToast?: boolean;
  sidebarItems?: StudioSidebarItem[];
  allowMultipleSidebars?: boolean;
}

export interface UserOrgSettings {
  showOrder?: any;
}

export interface ShowSettings {
  fullHD?: boolean;
  voiceConference?: boolean;
  dolbyClassic?: boolean;
  dailyConference?: boolean;
  showToastOnBackstageJoin?: boolean;
  mixSettings?: MixSettings;
  enhanceSettings?: EnhanceSettings;
  recordingCountdown?: number;
  defaultConfType?: ConfTypes;
  muteConfAudioNotifications?: boolean;
  recordingTone?: number;
  rawPCM?: boolean;
  enableChat?: boolean;
  maxQuality?: boolean;
}

export enum ConfTypes {
  VOICE = 'voice',
  CLASSIC = 'classic',
  DAILY = 'daily',
}

export const enum Themes {
  LIGHT = 'light-theme',
  DARK = 'dark-theme',
}

export enum RecordingTones {
  NONE = 0,
  TONE_1 = 1,
  TONE_2 = 2,
  SCREEN_READER = 3,
}

export interface EnhanceSettings {
  loudness?: {
    enable?: boolean;
    target_level?: number;
    dialog_intelligence?: boolean;
    speech_threshold?: number;
    peak_limit?: number;
    peak_reference?: 'true_peak' | 'sample';
  };
  dynamics?: {
    range_control?: {
      amount?: 'low' | 'medium' | 'high' | 'max';
      enable?: boolean;
    };
  };
  noise?: {
    reduction?: {
      enable?: boolean;
      amount?: 'low' | 'medium' | 'high' | 'max' | 'auto';
    };
  };
  filter?: {
    dynamic_eq?: {
      enable?: boolean;
    };
    high_pass?: {
      enable?: boolean;
      frequency?: number;
    };
    hum?: {
      enable?: boolean;
    };
  };
  speech?: {
    isolation?: {
      enable?: boolean;
      amount?: number;
    };
    sibilance?: {
      reduction?: {
        enable?: boolean;
        amount?: 'low' | 'medium' | 'high' | 'max';
      };
    };
    plosive?: {
      reduction?: {
        enable?: boolean;
        amount?: 'low' | 'medium' | 'high' | 'max';
      };
    };
    click?: {
      reduction?: {
        enable?: boolean;
        amount?: 'low' | 'medium' | 'high' | 'max';
      };
    };
  };
  music?: {
    detection?: {
      enable?: boolean;
    };
  };
}

export interface MixSettings {
  individual?: {
    hipfilter?: boolean;
    denoise?: boolean;
  };
  master?: {
    loudnesstarget?: number;
    leveler?: boolean;
    gate?: boolean;
    crossgate?: boolean;
  };
}

// export interface ApiSettings {}

export interface ApiKey {
  key: string;
  date: string;
  name: string;
  id?: string;
  revoked?: boolean;
  show?: boolean;
}

export const enum SettingsCollections {
  APP = 'app',
  CONFERENCE = 'conference',
  ENHANCE_SETTINGS = 'enhanceSettings',
  MIX_SETTINGS = 'mixSettings',
  SHOW_SETTINGS = 'showSettings',
  WEBHOOKS = 'webhooks',
  API = 'api',
}
