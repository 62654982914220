import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { timer, Subscription } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { ModalController } from '@ionic/angular';

import { WindowToken } from '../../services/window/window';
import { DolbyService } from '../../services/dolby/dolby.service';
import { AuthService } from '../../services/auth/auth.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SessionsService } from '../../services/sessions/sessions.service';
import { Session, UserModel } from '@sc/types';
import { UserService } from '../../services/user/user.service';
import { NameService } from '../../services/name/name.service';
import { RecordingService } from '../../services/recording/recording.service';
import { DailyService } from '../../services/daily/daily.service';

@Component({
  selector: 'sc-still-here',
  templateUrl: 'still-here.page.html',
  styleUrls: ['still-here.page.scss'],
})
export class StillHerePage implements OnInit, OnDestroy {
  dolbyConversation$ = this.dolbyService.dolbyConversation$;
  dailyCall$ = this.dailyService.dailyCall$;
  user: UserModel;
  leavingIn: number;
  guest = false;
  session: Session;
  name: string;
  subs: Array<Subscription> = [];

  constructor(
    private router: Router,
    private modalController: ModalController,
    private idle: Idle,
    private dolbyService: DolbyService,
    private dailyService: DailyService,
    private authService: AuthService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private sessionsService: SessionsService,
    private nameService: NameService,
    private recordingService: RecordingService,
    @Inject(WindowToken) private window: Window
  ) {}

  ngOnInit() {
    this.setupLeavingInTimer();
    this.setupUser();
  }

  async yes() {
    this.idle.watch();
    await this.modalController.dismiss();
    await this.analyticsService.track('selected yes i am still here');
  }

  async autoLeaveSession() {
    await this.recordingService.stopRecording();

    if (this.dolbyConversation$.value) {
      await this.dolbyService.leaveConversation();
    }

    if (this.dailyCall$.value) {
      await this.dailyService.leaveCall();
    }

    if (this.guest) {
      this.authService.logout();
      this.window.location.href = 'https://squadcast.fm';
    } else {
      const protocol = this.window.location.protocol;
      const hostName = this.window.location.host;
      this.window.location.href = `${protocol}//${hostName}/sessions`;
    }

    await this.analyticsService.track('automatically left recording session');
  }

  setupUser() {
    this.userService.getUser().then((user) => {
      this.user = user;

      if (!this.session) {
        this.setupSession();
      }

      // if (this.hostID === this.user.uid) {
      //   this.guest = false;
      // } else {
      //   this.guest = true;
      // }
    });
  }

  setupLeavingInTimer() {
    this.leavingIn = 60;

    const leavingInTimer = timer(1, 1000);

    const sub = leavingInTimer.subscribe((ticks) => {
      this.leavingIn = --this.leavingIn;

      if (this.leavingIn === 0) {
        this.modalController.dismiss();
        this.autoLeaveSession();
      }
    });

    this.subs.push(sub);
  }

  setupName() {
    const sub = this.nameService.getName(this.session.sessionID, this.user.uid).subscribe((name: any) => {
      return (this.name = name);
    });

    this.subs.push(sub);
  }

  setupSession() {
    const sub = this.sessionsService.studioSession$.subscribe((session: Session) => {
      this.session = session;
      // this.session.hostEmail = this.sessionsService.getHostEmail(this.session);
      // this.hostID = this.sessionsService.getHostID(this.session);

      if (!this.name) {
        this.setupName();
      }
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
