import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CalifoneService } from '../../services/califone/califone.service';
import { LafayetteService } from '../../services/lafayette/lafayette.service';
import { SettingsService } from '../../services/settings/settings.service';
import { ShowsService } from '../../services/shows/shows.service';
import { UserService } from '../../services/user/user.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { WebhookEvent, WebhookEventNames } from '@sc/types';
import { ShowSettings, ConfTypes, RecordingTones } from '@sc/types';
import { ActionConfirmPage } from '../action-confirm/action-confirm.page';

@Component({
  selector: 'sc-advanced-show-settings',
  templateUrl: 'advanced-show-settings.page.html',
  styleUrls: ['advanced-show-settings.page.scss'],
})
export class AdvancedShowSettingsPage implements OnInit, OnDestroy {
  dashboardShow$ = this.showsService.dashboardShow$;

  settings: ShowSettings;
  ConfTypes = ConfTypes;
  RecordingTones = RecordingTones;
  selectedRecordingTone = RecordingTones.NONE;

  subs: Subscription[] = [];

  constructor(
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private settingsService: SettingsService,
    private showsService: ShowsService,
    private userService: UserService,
    private lafayetteService: LafayetteService,
    private califoneService: CalifoneService,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.setupSettings();
  }

  setupSettings() {
    const sub = this.settingsService.dashboardShowSettings$.subscribe((settings) => {
      if (Number.isNaN(settings.recordingCountdown)) settings.recordingCountdown = 0;
      this.settings = settings;
      if (settings.recordingTone) this.selectedRecordingTone = settings.recordingTone;
    });

    this.subs.push(sub);
  }

  setConfType(type: ConfTypes) {
    if (this.settings.defaultConfType === type) return;
    const newSettings: ShowSettings = { defaultConfType: type };
    if (this.settings.dolbyClassic || this.settings.dolbyClassic === false) newSettings.dolbyClassic = null;
    if (this.settings.voiceConference || this.settings.voiceConference === false) newSettings.voiceConference = null;
    if (this.settings.dailyConference || this.settings.dailyConference === false) newSettings.dailyConference = null;
    this.settingsService.setShowSettings(newSettings, this.dashboardShow$.value.showID);
    this.analyticsService.track(`set show default conference type`, {
      showID: this.dashboardShow$.value.showID,
      confType: type,
    });
  }

  toggleFullHD(event: ICustomEvent) {
    this.settingsService.setShowSettings({ fullHD: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled full hd`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  toggleDefaultSessionQuality(event: ICustomEvent) {
    this.settingsService.setShowSettings({ maxQuality: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled default session quality`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  togglePCM(event: ICustomEvent) {
    this.settingsService.setShowSettings({ rawPCM: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled raw pcm`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  toggleDolbyClassic(event: ICustomEvent) {
    this.settingsService.setShowSettings({ dolbyClassic: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled dolby classic conference`, {
      showID: this.dashboardShow$.value.showID,
      enabled: event.detail.checked,
    });
  }

  changeRecordingCountdown(event: ICustomEvent) {
    this.settingsService.setShowSettings({ recordingCountdown: event.detail.value }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled countdown`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  changeRecordingTone(value: RecordingTones) {
    this.settingsService.setShowSettings({ recordingTone: value }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`changed recording tone`, {
      showID: this.dashboardShow$.value.showID,
      value,
    });
  }

  async playRecordingTone() {
    if (this.selectedRecordingTone === RecordingTones.SCREEN_READER) {
      const alert = document.getElementById('aria-alert');
      alert.setAttribute('aria-label', 'Recording Started');
      await new Promise((resolve) => setTimeout(resolve, 1200));
      alert.setAttribute('aria-label', 'Recording Stopped');
    } else {
      const start = new Audio(`assets/audio/record-start${this.selectedRecordingTone}.mp3`);
      start.onended = async () => {
        await new Promise((resolve) => setTimeout(resolve, 200));
        const stop = new Audio(`assets/audio/record-stop${this.selectedRecordingTone}.mp3`);
        stop.play();
      };
      start.play();
    }
  }

  async openDeleteShowModal() {
    const show = this.dashboardShow$.value;
    const parentModal = await this.modalController.getTop();
    const modal = await this.modalController.create({
      component: ActionConfirmPage,
      componentProps: {
        title: 'Delete Show',
        message: `Are you sure you want to delete ${show.showName}? This will remove all of it's SESSIONS and RECORDINGS.`,
        img: show.showImg,
        buttons: [
          {
            label: 'Delete Show',
            handler: async () => {
              try {
                await this.lafayetteService.deleteShow(show.showID, this.userService.activeUser$.value);
                parentModal.dismiss();
                this.router.navigate(['/dashboard'], { replaceUrl: true });

                const event: WebhookEvent = {
                  name: WebhookEventNames.SHOW_DELETED,
                  orgID: show.orgID,
                  showID: show.showID,
                  showName: show.showName,
                };
                await this.califoneService.emitWebhookEvent(event, show.orgID);

                this.toastrService.success(null, `Successfully deleted Show`, {
                  progressBar: true,
                  progressAnimation: 'decreasing',
                  closeButton: true,
                  tapToDismiss: false,
                  timeOut: 5 * 1000,
                  toastComponent: GeneralToastComponent,
                });
                await this.analyticsService.track('deleted show', { showID: show.showID, success: true });
              } catch (error) {
                this.toastrService.error(null, `Failed to delete Show`, {
                  progressBar: true,
                  progressAnimation: 'decreasing',
                  closeButton: true,
                  tapToDismiss: false,
                  timeOut: 5 * 1000,
                  toastComponent: GeneralToastComponent,
                });
                await this.analyticsService.track('deleted show', { showID: show.showID, success: false });
              }
              modal.dismiss();
            },
            type: 'danger',
          },
          {
            label: 'Cancel',
            handler: () => {
              modal.dismiss();
              this.analyticsService.track('closed delete show modal', { showID: show.showID });
            },
          },
        ],
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      cssClass: 'action-confirm-modal',
    });

    modal.present();
    this.analyticsService.track('selected delete show', { showID: show.showID });
  }

  toggleNotifyBackstage(event: ICustomEvent) {
    this.settingsService.setShowSettings(
      { showToastOnBackstageJoin: event.detail.checked },
      this.dashboardShow$.value.showID
    );
    this.analyticsService.track(`toggled show toast on backstage join`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  toggleMuteConfAudioNotifications(event: ICustomEvent) {
    this.settingsService.setShowSettings(
      { muteConfAudioNotifications: event.detail.checked },
      this.dashboardShow$.value.showID
    );
    this.analyticsService.track(`toggled mute conf audio notifications`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  toggleChatInShow(event: ICustomEvent) {
    this.settingsService.setShowSettings({ enableChat: event.detail.checked }, this.dashboardShow$.value.showID);
    this.analyticsService.track(`toggled chat in show`, {
      showID: this.dashboardShow$.value.showID,
      value: event.detail.value,
    });
  }

  close() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
