<sc-dashboard-layout accountPage class="wide">
  <form (ngSubmit)="editing && editingShow ? editShow() : createShow()">
    <div class="account-settings-header sticky-blur-header">
      <div class="header-content">
        <ion-icon src="assets/icons/24px/cover-flow.svg"></ion-icon>
        <h1 *ngIf="!(editing && editingShow)" class="ion-no-margin bold">Create new show</h1>
        <h1 *ngIf="editing && editingShow" class="ion-no-margin bold">Show Settings</h1>
      </div>
      <div class="header-buttons">
        <ion-button aria-label="Cancel" (click)="cancel()" class="text ion-margin-top"> Cancel </ion-button>
        <ion-button
          aria-label="Create Show"
          *ngIf="!(editing && editingShow)"
          class="primary ion-margin-top"
          [disabled]="nameCtrl.invalid"
          type="submit">
          Create Show
        </ion-button>

        <ion-button
          aria-label="Save Changes"
          *ngIf="editing && editingShow"
          class="primary ion-margin-top"
          [disabled]="nameCtrl.invalid"
          type="submit">
          Save<span class="ion-hide-lg-down">&nbsp;Changes</span>
        </ion-button>
      </div>
    </div>
    <div class="account-settings-content image-select-col">
      <div class="content-column">
        <ion-item class="input-large" lines="none">
          <ion-label position="stacked"> Show Name </ion-label>

          <ion-input
            #nameCtrl="ngModel"
            [(ngModel)]="showName"
            (keyup.enter)="!nameCtrl.invalid && (editing && editingShow ? editShow() : createShow())"
            name="showName"
            type="text"
            placeholder="Show Name"
            scAutoFocus
            minlength="2"
            required>
            <div class="validation-error" *ngIf="nameCtrl.invalid && nameCtrl.touched">
              <p *ngIf="nameCtrl.errors?.required">Show name is required</p>
              <p *ngIf="nameCtrl.errors?.minlength">Show name should be at least 2 chars long</p>
            </div>
          </ion-input>
        </ion-item>

        <ion-item class="input-large" lines="none">
          <ion-label position="stacked"> Description </ion-label>

          <ion-input [(ngModel)]="showDescription" name="showDescription" type="text" placeholder="Description">
          </ion-input>
        </ion-item>

        <div *ngIf="(role$ | async) >= roleLimits.TEAM_CREATE" class="member-select-container">
          <p class="micro-regular">
            Show Members

            <ion-button
              (click)="openRolesInfoMenu($event)"
              tooltip="Learn More About Roles"
              aria-label="Learn More About Roles"
              class="text ion-no-padding role-info-button">
              <ion-icon src="assets/icons/16px/info-outline.svg"></ion-icon>
            </ion-button>
          </p>

          <ng-select
            #showMembersSelect
            aria-label="Select show members or enter email to invite"
            addTagText="Invite email: "
            [addTag]="addTag"
            placeholder="Email or Team Member"
            appearance="outline"
            notFoundText="No one available, add an email?"
            [multiple]="true"
            [clearable]="false"
            [selectOnTab]="true"
            (add)="memberAdd($event)"
            [hideSelected]="true">
            <ng-option *ngFor="let member of availableMembers" [value]="member">
              <sc-team-member-list-item [member]="member"></sc-team-member-list-item>
            </ng-option>
          </ng-select>
        </div>

        <div *ngIf="showMembers.length" class="members-list">
          <div
            *ngFor="let member of showMembers"
            class="member"
            attr.aria-label="For {{ member.displayName || member.email || member.label }}">
            <sc-team-member-list-item *ngIf="(role$ | async) >= roleLimits.TEAM_READ" [member]="member">
            </sc-team-member-list-item>

            <ion-select
              *ngIf="(role$ | async) >= roleLimits.TEAM_UPDATE && !member.externalInvite"
              class="member-role-select"
              value="{{ member.role }}"
              interface="popover"
              (ionChange)="roleChange($event, member)">
              <ion-select-option value="{{ roles.SHOW_MANAGER }}"> Show Manager </ion-select-option>
              <ion-select-option value="{{ roles.SHOW_TALENT }}"> Show Talent </ion-select-option>
              <ion-select-option value="{{ roles.FILE_MANAGER }}"> File Manager </ion-select-option>
              <ion-select-option class="remove-member-option" value="remove"> Remove </ion-select-option>
            </ion-select>

            <ion-button
              *ngIf="member.externalInvite"
              (click)="cancelInvite(member.externalInvite)"
              aria-label="Cancel invite"
              class="text">
              Cancel Invite
            </ion-button>
          </div>
        </div>
      </div>
      <div class="image-column">
        <p class="micro-regular ion-no-padding ion-no-margin">Show Art<span class="ion-hide-lg-down">work</span></p>
        <div *ngIf="!imageSelection" (click)="inputFile.click()" id="artworkIcon">
          <ion-icon src="assets/icons/24px/add.svg"> </ion-icon>
        </div>
        <ion-img *ngIf="imageSelection" id="artwork" [src]="imageFile"></ion-img>
        <ion-button
          *ngIf="(role$ | async) >= roleLimits.SHOW_UPDATE"
          (click)="inputFile.click()"
          class="line ion-no-margin"
          size="small">
          <ion-icon src="assets/icons/24px/upload.svg" slot="start"> </ion-icon>

          <ion-label slot="end"> {{ !imageSelection ? 'Select Image' : 'Change Image' }} </ion-label>
        </ion-button>

        <input
          #inputFile
          type="file"
          accept="image/png | image/jpeg | image/jpg"
          (change)="photoInputChange($event)"
          id="input-file"
          style="display: none" />
      </div>
    </div>
    <div *ngIf="editing && editingShow">
      <div class="advanced-settings-header">
        <ion-icon src="assets/icons/24px/settings.svg"></ion-icon>
        <h2 class="ion-no-margin bold">Advanced Show Settings</h2>
      </div>
    </div>
    <div *ngIf="editing && editingShow" class="advanced-settings">
      <ion-list *ngIf="settings">
        <!-- <ion-item aria-label="Default Conference Type" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Default Conference Type </strong>
              <sc-learn-more topic="confTypes"></sc-learn-more>
            </span>
            <p>
              This setting does <b>not</b> impact you or your guest’s Primary HD Recordings. The conference references
              the realtime connection in the studio and any processing will apply to cloud recordings only.

              <strong>Please note that some of these settings will be leaving in the near future!</strong>
            </p>
            <div class="conf-cards">
              <ion-button
              aria-label="Set Default Conference Type to Daily.co"
              class="conf-card"
              fill="clear"
              (click)="setConfType(ConfTypes.DAILY)"
              [class.active]="!settings.defaultConfType || settings.defaultConfType === ConfTypes.DAILY">
              <div class="conf-card-content">
                <div class="card-content-header">
                  <ion-icon src="assets/icons/24px/dailyco-logo.svg"></ion-icon>
                  <h5>Daily.co</h5>
                </div>
                <ul>
                  <li>Individual Track Backups </li>
                  <li>Adaptive Bitrate ( 700kbps | 320kbps )</li>
                  <li>No Processing</li>
                </ul>
              </div>
              
            </ion-button>
            <div (click)="setConfType(ConfTypes.CLASSIC)">
              <ion-button
                aria-label="Set Default Conference Type to Classic"
                class="conf-card"
                fill="clear"
                [disabled]="true"
                [class.active]="!settings.defaultConfType || settings.defaultConfType === ConfTypes.CLASSIC">
                <div class="conf-card-content">
                  <div class="card-content-header">
                    <ion-icon src="assets/icons/24px/optimize-audio.svg"></ion-icon>
                    <h5>Classic Conference</h5>
                  </div>
                  <ul>
                    <li>Low Latency Connection</li>
                    <li>High Audio Quality (128kbps | 48khz)</li>
                    <li>No Processing</li>
                  </ul>
                </div>
              </ion-button>
            </div>
            <div (click)="setConfType(ConfTypes.VOICE)">
              <ion-button
                aria-label="Set Default Conference Type to Voice"
                class="conf-card"
                fill="clear"
                [disabled]="true"
                [class.active]="settings.defaultConfType === ConfTypes.VOICE">
                <div class="conf-card-content">
                  <div class="card-content-header">
                    <ion-icon src="assets/icons/24px/dolby-voice-2.svg"></ion-icon>
                    <h5>Dolby Voice Conference</h5>
                  </div>
                  <ul>
                    <li>Realtime Mastering / Noise Reduction</li>
                    <li>Lower Audio Quality (42kbps | 48khz)</li>
                    <li>Individual Track Cloud Backups</li>
                  </ul>
                </div>
              </ion-button>
            </div>

            </div>
          </ion-label>
        </ion-item> -->

        <ion-item aria-label="Default Session Quality" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Maximize Recording Quality </strong>
              <sc-learn-more topic="fullHD"></sc-learn-more>
            </span>

            <p>
              Enables maximum possible recording quality for all participants in all sessions created. Session quality
              settings can also be manually set within a session. When enabled, primary recordings will use the maximum
              quality enabled by their equipment and browser. Max quality is
              <ion-badge color="warning" tooltip="4K Recordings are currently in beta!">BETA</ion-badge>
              4K (2160p) with PCM (lossless) audio enabled.
            </p>
          </ion-label>

          <ion-button
            *ngIf="!(plan$ | async)?.videoUHD"
            [upgrade]="!(plan$ | async)?.videoUHD"
            [class.disabled]="!(plan$ | async)?.videoUHD"
            tooltip="Upgrade To Unlock"
            [routerLink]="['/account/subscription']"
            oncontextmenu="false"
            class="primary"
            expand="block">
            Upgrade to enable 4K!
          </ion-button>
          <ion-toggle
            *ngIf="(plan$ | async)?.videoUHD"
            [checked]="(plan$ | async)?.videoUHD ? settings.maxQuality : false"
            (ionChange)="toggleDefaultSessionQuality($event)"
            color="primary"
            mode="ios"
            slot="end">
          </ion-toggle>
        </ion-item>

        <!-- <ion-item aria-label="Toggle Notify When Backstage Participant Joins" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Backstage Participant Join Notification </strong>
              <sc-learn-more topic="backstageJoin"></sc-learn-more>
            </span>
            <p>Receive notifications when someone joins backstage.</p>
          </ion-label>

          <ion-toggle
            [checked]="settings.showToastOnBackstageJoin !== false"
            (ionChange)="toggleNotifyBackstage($event)"
            color="primary"
            mode="ios"
            slot="end">
          </ion-toggle>
        </ion-item> -->

        <ion-item aria-label="Toggle Recording Countdown" lines="full" class="recording-countdown-slider">
          <div>
            <ion-label color="lightest" class="ion-text-wrap">
              <span class="label-header">
                <strong>Recording Start Countdown <span *ngIf="settings.recordingCountdown === 10">🚀</span></strong>
                <sc-learn-more topic="recordingCountdown"></sc-learn-more>
              </span>

              <p>
                Display a countdown to notify participants that recording is about to start. Select 0 if you want the
                recording to start immediately.
              </p>
            </ion-label>

            <ion-range
              [value]="settings.recordingCountdown ?? 0"
              [min]="0"
              [max]="10"
              [pin]="true"
              [ticks]="true"
              [snaps]="true"
              (ionChange)="changeRecordingCountdown($event)"></ion-range>
          </div>
        </ion-item>

        <ion-item aria-label="Mute Conference Join/Leave Tone" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Mute Conference Join/Leave Tone </strong>
              <sc-learn-more topic="joinLeaveTone"></sc-learn-more>
            </span>
            <p>Mute audio tone when someone joins or leaves the main stage for everyone in the session.</p>
          </ion-label>

          <ion-toggle
            [checked]="settings.muteConfAudioNotifications"
            (ionChange)="toggleMuteConfAudioNotifications($event)"
            color="primary"
            mode="ios"
            slot="end">
          </ion-toggle>
        </ion-item>

        <ion-item aria-label="Enable Chat in Show Sessions" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Enable Chat in Show Sessions </strong>
              <sc-learn-more topic="enableChatInShow"></sc-learn-more>
            </span>
            <p>
              Enable or Disable Chat in Show Sessions. This will be respected across all sessions for this particular
              show. (On by Default).
            </p>
          </ion-label>

          <ion-toggle
            [checked]="settings?.enableChat || (settings?.enableChat === undefined) === true"
            (ionChange)="toggleChatInShow($event)"
            color="primary"
            mode="ios"
            slot="end">
          </ion-toggle>
        </ion-item>

        <ion-item aria-label="Enable Recording Start/Stop Sound Effects" lines="full">
          <ion-label color="lightest" class="ion-text-wrap">
            <span class="label-header">
              <strong> Enable Recording Start/Stop Audio Tone </strong>
              <sc-learn-more topic="recordingAudioTone"></sc-learn-more>
            </span>
            <p>Play an audio tone to alert all participants that the recording has started or stopped.</p>
          </ion-label>

          <span class="recording-tone-select" slot="end">
            <ng-select
              [(ngModel)]="selectedRecordingTone"
              (change)="changeRecordingTone($event)"
              [clearable]="false"
              [searchable]="false">
              <ng-option [value]="RecordingTones.NONE">None</ng-option>
              <ng-option [value]="RecordingTones.TONE_1">Audio Tone 1</ng-option>
              <ng-option [value]="RecordingTones.TONE_2">Audio Tone 2</ng-option>
              <ng-option [value]="RecordingTones.SCREEN_READER">Screen Reader</ng-option>
            </ng-select>
            <ion-button
              aria-label="Preview Tones"
              *ngIf="selectedRecordingTone"
              (click)="playRecordingTone()"
              fill="outline">
              <ion-icon src="assets/icons/32px/play-solid-bold.svg" slot="icon-only"></ion-icon>
            </ion-button>
          </span>
        </ion-item>

        <sc-dolby-settings></sc-dolby-settings>

        <sc-auphonic-settings></sc-auphonic-settings>

        <h5>Danger Zone</h5>

        <ion-row>
          <ion-col
            size-xs="12"
            size-sm="12"
            offset-md="1"
            size-md="10"
            offset-lg="2"
            size-lg="8"
            offset-xl="3"
            size-xl="6">
            <ion-button (click)="openDeleteShowModal()" expand="block" id="delete-show-button">
              Delete Show
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-list>
    </div>
  </form>
</sc-dashboard-layout>
